import { type ReceiptsBulkURLResponse, type ReceiptsGetBulkURLParams } from 'src/api/fm/receipts/receipts.model'

export const GET_RECEIPTS_BULK_URL = 'GET_RECEIPTS_BULK_URL'
export const SET_RECEIPTS_BULK_URL = 'SET_RECEIPTS_BULK_URL'

export type GetReceiptsBulkUrl = {
    type: typeof GET_RECEIPTS_BULK_URL
    payload: ReceiptsGetBulkURLParams
}

export type SetReceiptsBulkUrl = {
    type: typeof SET_RECEIPTS_BULK_URL
    payload: ReceiptsBulkURLResponse
}

export type ReceiptsState = {
    bulkUrl: ReceiptsBulkURLResponse | null
}

export type ReceiptsActions = GetReceiptsBulkUrl | SetReceiptsBulkUrl
