export enum DriversLicenseDocumentStatus {
    Unknown = 'unknown',
    Ok = 'ok',
    Processing = 'processing',
    Rejected = 'rejected',
    Expired = 'expired',
    Suspected = 'suspected',
    Duplicated = 'duplicated',
    MissingData = 'missing-data',
    UnsupportedType = 'unsupported-type',
    FaceNotDetected = 'face-not-detected',
    Blocked = 'blocked',
    Requested = 'requested',
}

export enum DriversLicenseFaceCheckStatus {
    Unknown = 'unknown',
    Ok = 'ok',
    Processing = 'processing',
    Rejected = 'rejected',
}

/*
 * Soon, the driving-licence api will be changed to identification-document
 * this will bring in breaking changes. This is a do away for now.
 * */
export enum IdentificationDocumentType {
    Unspecified = 'unspecified',
    DrivingLicense = 'driving_licence',
    NationalID = 'national_id',
    Passport = 'passport',
    ResidencePermit = 'residence_permit',
    Visa = 'visa',
    WorkPermit = 'work_permit',
    Generic = 'generic',
    SwedishBankId = 'swedish_bank_id',
    NorwegianBankId = 'norwegian_bank_id',
    Vipps = 'vipps',
    SPID = 'spid',
    Itsme = 'itsme',
}

export interface GetDriversLicenseStatusResponse {
    data: {
        documentType: IdentificationDocumentType
        documentStatus: DriversLicenseDocumentStatus
        documentUsage: Record<string, DriversLicenseDocumentStatus>
        faceCheckStatus: DriversLicenseFaceCheckStatus
        identityProviderLink: string
        identityProviderName: string
    }
}

export interface DriversLicenseStatus {
    documentType: IdentificationDocumentType
    documentStatus: DriversLicenseDocumentStatus
    documentUsage: Record<string, DriversLicenseDocumentStatus>
    faceCheckStatus: DriversLicenseFaceCheckStatus
    identityProviderLink: string
    identityProviderName: string
}

export interface GetRiderResponse {
    data: Rider
}

export interface Rider {
    blockedDevicesCount: number
    paymentInfo: {
        brand: string
        last: string
        credit: { currency: string; balance: number }[]
        debt: { currency: string; balance: number }[]
        blocked: boolean
        blockedReason: string
        hasBusinessPaymentMethod: boolean
    }
    userInfo: RiderUserInfo
    walletTransactions: {
        type: string
        details: string
        description: string
        currency: string
        totalAmount: number
        date: string
    }[]
    source: string
    partnerInfo: { partnerId: string; partnerName: string }
}

export interface RiderUserInfo {
    id: string
    country: string
    email: string
    name: string
    phone: string
    referedBy: string
    roles: {
        global: string[]
        zones: { [key: string]: string[] }
    }
    addedAt: string
    deletedAt: string
    isBlocked: boolean
    associatedWithBlockedCard: boolean
    blockedByDevice: boolean
    hasAtleastOnePaidRide: boolean
}

export interface AccountSharingInfo {
    history: AccountSharingInfoDevice[]
    numberOfDeviceSwitches: number
    numberOfDeviceSwitchesForBlockPeriod: number
    unblockDate: string
    restricted: boolean
}

export interface AccountSharingInfoDevice {
    time: string
    reason: string
    deviceId: string
}
