import {
    GET_HUNTER_PARKING_PHOTO_BY_TASK_ID,
    GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS,
    APPEND_TASK_IDS_WITH_PARKING_PHOTO,
    SHOW_PARKING_PHOTO_FOR_TASK,
    CLOSE_SHOW_PARKING_PHOTO_FOR_TASK,
    SHOW_NEXT_HUNTER_PARKING_PHOTO,
    SHOW_PREVIOUS_HUNTER_PARKING_PHOTO,
    SET_IS_FETCHING_MORE_TASKS_START,
    SET_IS_FETCHING_MORE_TASKS_DONE,
    RESET_HUNTER_PARKING_PHOTO_STATE,
    type HunterParkingPhotosCurrentPage,
    type GetHunterParkingPhotoByTaskId,
} from 'src/redux/hunterParkingPhotos/hunterParkingPhotos.types'

export const getHunterParkingPhotoByTaskId = (taskId: string): GetHunterParkingPhotoByTaskId => ({
    type: GET_HUNTER_PARKING_PHOTO_BY_TASK_ID,
    payload: taskId,
})

export const getHunterParkingPhotoByTaskIdSuccess = (taskId: string, photoUrl: string) => ({
    type: GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS,
    payload: { taskId, photoUrl },
})

export const appendTaskIdsWithParkingPhoto = (taskIds: string[], currentPage: HunterParkingPhotosCurrentPage) => ({
    type: APPEND_TASK_IDS_WITH_PARKING_PHOTO,
    payload: { taskIds, currentPage },
})

export const showParkingPhotoForTask = (taskId: string) => ({
    type: SHOW_PARKING_PHOTO_FOR_TASK,
    payload: { taskId },
})

export const closeShowParkingPhotoForTask = () => ({
    type: CLOSE_SHOW_PARKING_PHOTO_FOR_TASK,
})

export const showNextHunterParkingPhoto = () => ({
    type: SHOW_NEXT_HUNTER_PARKING_PHOTO,
})

export const showPreviousHunterParkingPhoto = () => ({
    type: SHOW_PREVIOUS_HUNTER_PARKING_PHOTO,
})

export const setIsFetchingMoreTasksStart = () => ({
    type: SET_IS_FETCHING_MORE_TASKS_START,
})

export const setIsFetchingMoreTasksDone = () => ({
    type: SET_IS_FETCHING_MORE_TASKS_DONE,
})

export const resetHunterParkingPhotoState = () => ({
    type: RESET_HUNTER_PARKING_PHOTO_STATE,
})
