import { type PropsWithChildren } from 'react'
import * as Sentry from '@sentry/react'

interface Props {
    fallback: Sentry.FallbackRender
}

export const BaseErrorBoundary = ({ fallback, children }: PropsWithChildren<Props>) => (
    <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
)
