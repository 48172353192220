import { useEffect, useState } from 'react'
import { onAuthStateChanged, type User } from 'firebase/auth'
import { firebase } from 'src/firebase'

const useFirebaseAuthentication = () => {
    const [authUser, setAuthUser] = useState<string | User>('loading')

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebase.auth, authUser => setAuthUser(authUser!))
        return () => unsubscribe()
    }, [])

    return authUser
}

export default useFirebaseAuthentication
