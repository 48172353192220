import { type AxiosRequestHeaders } from 'axios'
import { auth } from 'src/api/auth'
import * as env from 'src/utils/env'

interface CustomAxiosRequestHeaders extends AxiosRequestHeaders {
    'X-App-Name': string
    'X-Source': string
    'X-Access-Token': string
    'X-App-Version': string
}

export const getHeaders = async (): Promise<AxiosRequestHeaders> => {
    const tokens = await auth.getTokens().catch(error => {
        console.error(error)
    })
    const xAccessToken = (tokens && tokens.accessToken) ?? ''

    return {
        'Content-Type': 'application/json',
        'X-App-Name': 'fm-dashboard',
        'X-Source': 'fm-dashboard',
        'X-Access-Token': xAccessToken,
        'X-App-Version': env.BUILD_SHORT_SHA,
    } as CustomAxiosRequestHeaders
}
