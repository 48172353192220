import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { initializeAppStore } from 'src/redux/app/app.store'
import 'react-datepicker/dist/react-datepicker.css'
import 'src/index.css'
import { App } from 'src/components/app/App'
import { Provider } from 'react-redux'
import * as env from 'src/utils/env'
import { heatmapMaker } from 'src/tracking/hotjar'

if (env.HEATMAP_TRACKING_ENABLED) {
    const heatmap = heatmapMaker()
    heatmap.init(env.HEATMAP_TRACKING_ID, env.HEATMAP_TRACKING_VERSION)
}

if (env.ERROR_REPORTING) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: env.APP_ENV,
        release: env.BUILD_SHORT_SHA,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0.01,
        beforeSend: event => {
            // Filter out ResizeObserver loop limit exceeded errors from Sentry
            if (event?.message?.includes('ResizeObserver loop limit exceeded')) {
                return null
            }

            return event
        },
    })
}

const store = initializeAppStore()

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
)
