import { ins } from 'src/api/axios'
import {
    type Task,
    type CloseTaskStatus,
    type ExecutionPriority,
    type ParkingPhotoSettings,
    type GetParkingPhotoSettingsResponse,
    type UpdateParkingPhotoSettingsResponse,
    type DeleteTasksBulkResponse,
    type GetTaskTypesResponse,
    type TaskType,
} from 'src/api/fm/tasks/tasks.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'
import { type EditedTaskPayload } from 'src/components/parts/active-tasks/editTaskForm/EditTaskForm'
import { type NewTaskReq } from 'src/components/parts/active-tasks/createTaskForm/task.types'

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway/createTask API documentation}
 */
export const createNewTask = async (task: NewTaskReq): Promise<Task | Error> => {
    const link = encodeURI('v1/fm/tasks')
    const headers = await getHeaders()

    return ins
        .post(link, task, { headers })
        .then(res => res.data.Data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway/updateTask API documentation}
 */
export const editTask = async (task: EditedTaskPayload): Promise<Task | Error> => {
    const link = encodeURI(`/v1/fm/tasks/${task.id}`)
    const headers = await getHeaders()
    return ins
        .put(link, task, { headers })
        .then(res => res.data.Data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export interface TaskBulkFields {
    executionPriority: ExecutionPriority
}

export const updateTasksBulk = async (taskIds: string[], taskBulkFields: TaskBulkFields) => {
    const link = encodeURI('v1/fm/tasks/bulk')
    const headers = await getHeaders()
    const data = {
        ...taskBulkFields,
        taskIds,
    }
    return ins
        .put(link, data, { headers })
        .then(res => res.data)
        .catch(e => console.log('error', e))
}

interface OrganizationActiveTasksRes {
    data: {
        data: Task[]
    }
}

export const getOrganizationActiveTasks = async (
    owner_id: string | undefined,
    operator_email: string | undefined,
): Promise<OrganizationActiveTasksRes | Error> => {
    const params = {
        owner_id,
        status: 'pending',
        include: 'auditlog,vehicle',
        operator_email,
        page_size: 1000,
    }
    const link = encodeURI('/v1/fm/tasks')
    const headers = await getHeaders()
    const res = ins
        .get(link, { headers, params })
        .then(res => {
            return res
        })
        .catch(e => {
            return e
        })
    return res
}

export const getActiveTasksForVehicle = async (vehicleId: string): Promise<Task[] | Error> => {
    const params = {
        vehicle_id: vehicleId,
        status: 'new,pending',
        include: 'auditlog,vehicle',
    }
    const link = encodeURI('v1/fm/tasks')
    const headers = await getHeaders()
    return ins
        .get(link, { headers, params })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getActiveTasksForVehicleBulk = async (vehicleIds: string[]): Promise<Task[] | Error> => {
    const vehicle_id = vehicleIds.join(',')

    const params = {
        vehicle_id,
        status: 'new,pending',
        include: 'auditlog,vehicle',
    }
    const link = encodeURI('v1/fm/tasks')
    const headers = await getHeaders()
    return ins
        .get(link, { headers, params })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway/listTasks API documentation}
 */
export const getHunterHistoryTasks = async (
    id: string,
    pageNum: number,
    size = 10,
    sortParam = '-created_at',
): Promise<Task[] | Error> => {
    const link = encodeURI(
        `v1/fm/tasks?operator_id=${id}&status=fulfilled,declined&page_number=${pageNum}&page_size=${size}&sort=${sortParam}&include=auditlog&parking_photos=true`,
    )
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway/listTasks API documentation}
 */
export const getOrganizationHistoryTasks = async (
    owner_id: string,
    page_number: number,
    operator_email?: string,
    page_size = 10,
    sort = 'created_at',
): Promise<Task[] | Error> => {
    const params = {
        owner_id,
        page_number,
        page_size,
        sort,
        operator_email,
        status: 'fulfilled,declined',
        include: 'auditlog',
        parking_photos: true,
    }
    const link = 'v1/fm/tasks'
    const headers = await getHeaders()
    return ins
        .get(link, { headers, params })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway/listTasks API documentation}
 */
export const getScooterHistoryTasks = async (
    vehicle_id: string,
    page_number: number,
    page_size = 10,
    sort = '-created_at',
): Promise<Task[] | Error> => {
    const params = {
        vehicle_id,
        page_number,
        page_size,
        sort,
        status: 'fulfilled,declined',
        include: 'auditlog,deleted-tasks',
        parking_photos: true,
    }
    const link = 'v1/fm/tasks'
    const headers = await getHeaders()

    return ins
        .get(link, { headers, params })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const deleteTask = async (id: string): Promise<undefined | Error> => {
    const link = encodeURI(`v1/fm/tasks/${id}`)
    const headers = await getHeaders()

    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const deleteTasksBulk = async (taskIds: string[]): Promise<DeleteTasksBulkResponse | Error> => {
    const data = { taskIds }
    const link = encodeURI('v1/fm/tasks')
    const headers = await getHeaders()

    return ins
        .delete(link, { data, headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway/closeTaskV2 API documentation}
 */
export async function closeTask(
    taskId: string,
    status: CloseTaskStatus = 'fulfilled',
    warehouseId: string | undefined,
) {
    const link = encodeURI(`v2/fm/tasks/${taskId}/close`)
    const headers = await getHeaders()
    const body = { status, siteId: warehouseId }

    return ins
        .post(link, body, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

interface TaskData {
    id: string
    warehouseId: string | undefined
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway/closeTasksV2 API documentation}
 */
export const closeTasksBulk = async (tasksData: TaskData[], status: CloseTaskStatus) => {
    const tasks = tasksData.map(task => ({ taskId: task.id, siteId: task.warehouseId, status }))
    const body = { tasks }
    const link = encodeURI('v2/fm/tasks/bulk/close')
    const headers = await getHeaders()

    return ins
        .post(link, body, { headers })
        .then(res => res)
        .catch(e => e)
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway%20settings/getParkingPhotoSetting API documentation}
 */
export const getParkingPhotoSettings = async (zoneId: string): Promise<ParkingPhotoSettings | Error> => {
    const link = encodeURI(`/v1/fm/tasks/settings/parking-photo/${zoneId}`)
    const headers = await getHeaders()

    return ins
        .get<GetParkingPhotoSettingsResponse>(link, { headers })
        .then(res => res.data.data.flows)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/tasks%20gateway%20settings/getParkingPhotoSetting API documentation}
 */
export const updateParkingPhotoSettings = async (
    zoneId: string,
    parkingPhotoSettings: ParkingPhotoSettings,
): Promise<ParkingPhotoSettings | Error> => {
    const link = encodeURI(`/v1/fm/tasks/settings/parking-photo/${zoneId}`)
    const data = {
        data: {
            flows: parkingPhotoSettings,
        },
    }
    const headers = await getHeaders()

    return ins
        .put<UpdateParkingPhotoSettingsResponse>(link, data, { headers })
        .then(res => res.data.data.flows)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/tasks%20gateway/getTaskTypes API documentation}
 */
export const getTaskTypes = async (): Promise<TaskType[] | Error> => {
    const link = encodeURI('/v1/fm/tasks/task-types')
    const headers = await getHeaders()

    return ins
        .get<GetTaskTypesResponse>(link, { headers })
        .then(res => mapGetTaskTypes(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetTaskTypes = (response: GetTaskTypesResponse): TaskType[] => {
    return response.taskTypes
}
