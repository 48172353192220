import { ins } from 'src/api/axios'
import type {
    BulkMaintenanceNeedsResult,
    CreateMaintenanceNeedsResponse,
    DeleteMaintenanceNeedsResponse,
    GetMaintenanceNeedVehiclesResponse,
    MaintenanceNeed,
    MaintenanceNeedVehicle,
} from 'src/api/fm/maintenanceNeed/maintenanceNeed.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/maintenance%20need/getMaintenanceNeedsByVehicleIdentifiers API documentation}
 */
export const getMaintenanceNeedVehicles = async (
    vehicleIds?: string[],
    vehicleShorts?: string[],
): Promise<MaintenanceNeedVehicle[] | Error> => {
    const params = {
        vehicle_ids: vehicleIds?.join(','),
        vehicle_shorts: vehicleShorts?.join(','),
    }
    const url = '/v1/fm/repairs/maintenance-needs'
    const headers = await getHeaders()

    return ins
        .get<GetMaintenanceNeedVehiclesResponse>(url, { headers, params })
        .then(res => mapGetMaintenanceNeedVehicle(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetMaintenanceNeedVehicle = (response: GetMaintenanceNeedVehiclesResponse): MaintenanceNeedVehicle[] => {
    return response.data.map(({ vehicleId, vehicleShort, vehicleModelId, maintenanceNeeds }) => ({
        vehicleId,
        vehicleShort,
        vehicleModelId,
        maintenanceNeeds: maintenanceNeeds
            ? maintenanceNeeds.map(({ id, title, type, description, repairTypes, createdAt }) => ({
                  id,
                  title,
                  type,
                  description,
                  createdAt,
                  repairTypes: repairTypes
                      ? repairTypes.map(({ id, name }) => ({
                            id,
                            name,
                        }))
                      : [],
              }))
            : [],
    }))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/maintenance%20need/createMaintenanceNeeds API documentation}
 */
export const createMaintenanceNeeds = async (
    vehicleIds: string[],
    maintenanceNeed: MaintenanceNeed,
): Promise<BulkMaintenanceNeedsResult | Error> => {
    const url = '/v1/fm/repairs/maintenance-needs/bulk'
    const headers = await getHeaders()

    return ins
        .post(url, { vehicleIds, maintenanceNeed }, { headers })
        .then(res => mapCreateMaintenanceNeeds(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapCreateMaintenanceNeeds = (response: CreateMaintenanceNeedsResponse): BulkMaintenanceNeedsResult => {
    return {
        successful: response.successful.map(s => s.vehicleId),
        failed: response.failed.map(f => f.vehicleId),
    }
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/maintenance%20need/updateMaintenanceNeed API documentation}
 */
export const updateMaintenanceNeed = async (maintenanceNeed: MaintenanceNeed): Promise<void | Error> => {
    const url = encodeURI(`/v1/fm/repairs/maintenance-needs/${maintenanceNeed.id}`)
    const headers = await getHeaders()

    return ins
        .put(url, maintenanceNeed, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/maintenance%20need/deleteMaintenanceNeeds API documentation}
 */
export const deleteMaintenanceNeeds = async (
    maintenanceNeedIds: string[],
): Promise<BulkMaintenanceNeedsResult | Error> => {
    const url = '/v1/fm/repairs/maintenance-needs/bulk'
    const headers = await getHeaders()
    const data = { ids: maintenanceNeedIds }

    return ins
        .delete(url, { headers, data })
        .then(res => mapDeleteMaintenanceNeeds(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapDeleteMaintenanceNeeds = (response: DeleteMaintenanceNeedsResponse): BulkMaintenanceNeedsResult => {
    return {
        successful: response.successful,
        failed: response.failed,
    }
}
