import {
    ADD_MAAS_USER,
    EDIT_MAAS_USER,
    GET_MAAS_USERS,
    SET_MAAS_USERS,
    type AddMaasUser,
    type EditMaasUser,
    type DeleteMaasUser,
    DELETE_MAAS_USER,
} from 'src/redux/maasUsers/maasUsers.types'
import { type MaasUser, type DraftMaasUser } from 'src/components/app/utils/maas/types'

export const getMaasUsers = () => ({
    type: GET_MAAS_USERS,
})

export const setMaasUsers = (maasUsers: MaasUser[]) => ({
    type: SET_MAAS_USERS,
    payload: maasUsers,
})

export const addMaasUser = (maasUser: DraftMaasUser): AddMaasUser => ({
    type: ADD_MAAS_USER,
    payload: maasUser,
})

export const editMaasUser = (maasUser: MaasUser): EditMaasUser => ({
    type: EDIT_MAAS_USER,
    payload: maasUser,
})

export const deleteMaasUser = (maasUser: MaasUser): DeleteMaasUser => ({
    type: DELETE_MAAS_USER,
    payload: maasUser,
})
