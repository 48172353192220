import { createSelector } from 'reselect'
import { type AppState } from 'src/redux/app/app.store'
import { type ZoneOption } from 'src/redux/zone/zone.types'

export const selectCurrentZone = (state: AppState) => state.zone.currentZone
export const selectCurrentZoneDetailsMaxSpeed = (state: AppState) => state.zone.currentZone.data?.maxSpeed
export const selectZoneOptions = (state: AppState): ZoneOption[] => state.zone.zoneOptions
export const selectAllZones = (state: AppState) => state.zone.allZones
export const selectAllZoneCountries = (state: AppState) => state.zone.allCountries
export const selectZoneEditCountry = (state: AppState) => state.zone.editCountry
export const selectZoneStringOptions = createSelector([selectZoneOptions], zoneOptions =>
    zoneOptions.map(({ label }) => {
        return { value: label, label }
    }),
)
export const selectZoneFeatures = (state: AppState) => state.zone.zoneFeatures
export const selectVehiclePolicy = (state: AppState) => state.zone.vehiclePolicy
export const selectDeleteZone = (state: AppState) => state.zone.deleteZone
export const selectZones = (state: AppState) => state.zone.zones
export const selectZoneVehiclePricing = (state: AppState) => state.zone.vehiclePricing
export const selectVoiPassGroups = (state: AppState) => state.zone.voiPassGroups
export const selectZoneParkingAssistantSettings = (state: AppState) => state.zone.zoneParkingAssistantSettings
export const selectZoneParkingPhotoSettings = (state: AppState) => state.zone.parkingPhotoSettings
export const selectZoneThresholds = (state: AppState) => state.zone.currentZone?.data?.thresholds
export const selectZoneId = (state: AppState) => state.zone.currentZone?.data?.zone_id
export const selectVoiPassDiscount = (state: AppState) => state.zone.voiPassDiscount
