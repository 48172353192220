import FuzzySearch from 'fuzzy-search'
import { type ZoneOption } from 'src/redux/zone/zone.types'
import { VehiclePricingType, type VoiPassGroup, VoiPassPeriodUnitType } from 'src/api/fm/pricing/pricing.model'
import { centsToPriceString } from 'src/utils/number/numberUtils'
import { isFreeUnlockPass } from 'src/utils/string/stringUtils'
import { type Zone, type ParkingMode } from 'src/api/fm/zones/zones.model'
import { type SelectOption } from 'src/ui-kit/selectTooltip/types'

export interface MappedVoiPassGroup extends VoiPassGroup {
    priceLabel: string
    discountedLabel: string
    activeStatus: string
    categoryLabel: string
}

export const getNumberOfZones = (country: string, zones: Record<string, any>[]) => {
    const zonesInCountry = zones.filter(z => z.country === country)
    return zonesInCountry.length
}

export const getZoneLabel = (zoneId: string, zones: ZoneOption[]) => {
    const found = zones.filter(z => z.value === zoneId)
    return found[0] ? found[0].label : 'not found'
}

export const parkingSpotOptions: SelectOption<ParkingMode>[] = [
    { label: 'Free-floating', value: 'free-floating' },
    { label: 'Parking spot', value: 'parking-spot' },
    { label: 'Soft Mpz', value: 'soft-mpz' },
]

export const displaySpeedUnitOptions = [
    { label: 'km/h', value: 'km/h' },
    { label: 'mph', value: 'mph' },
]

export const motorPowerOptions = [
    { label: '250W', value: '250' },
    { label: '350W', value: '350' },
]

export const assistLevelOptions = [
    { label: 'No Assist', value: 'NO_ASSIST' },
    { label: 'Very Low', value: 'VERY_LOW' },
    { label: 'Low', value: 'LOW' },
    { label: 'Medium', value: 'MED' },
    { label: 'High', value: 'HIGH' },
    { label: 'Max', value: 'MAX' },
]

export const getSleepStatusForZone = (zoneId: string, zones: Zone[]): boolean => {
    const zone = zones.find(z => z.zone_id === zoneId)
    return zone ? zone.isSleeping : false
}

export const pricingVehicleOptions = [
    { label: 'Scooter', value: VehiclePricingType.Scooter as string },
    { label: 'E-Bike', value: VehiclePricingType.EBike as string },
]

/**
 * Adds labels that are used to filter voi pass groups
 */
export const mapVoiPassGroups = (voiPassGroups: VoiPassGroup[] | null): MappedVoiPassGroup[] => {
    if (!voiPassGroups) {
        return []
    }

    return voiPassGroups.map(voiPass => ({
        ...voiPass,
        priceLabel: `${centsToPriceString(voiPass.price)} ${voiPass.currency}`,
        discountedLabel:
            voiPass.discountAmount >= 0 && voiPass.discountId
                ? `${centsToPriceString(voiPass.discountAmount)} ${voiPass.currency}`
                : '-',
        activeStatus: voiPass.active ? 'Active' : 'Disabled',
        categoryLabel: isFreeUnlockPass(voiPass.name) ? 'Free Unlock' : voiPass.promotionCategory || 'Regular',
    }))
}

export const filterVoiPassGroups = (voiPassGroups: MappedVoiPassGroup[], filter: string): MappedVoiPassGroup[] => {
    if (!filter) {
        return voiPassGroups
    }

    const searcher = new FuzzySearch(
        voiPassGroups,
        ['name', 'categoryLabel', 'priceLabel', 'discountedLabel', 'activeStatus'],
        {
            caseSensitive: false,
        },
    )

    return searcher.search(filter)
}

// Only one Voi pass type is available for creation/editing at the moment
export const periodDayPassName = 'one_day_pass'
export const freeUnlockDayPassName = 'one_day_pass_free_unlock'
export const editableVoiPassPeriod = 1
export const editableVoiPassPeriodUnit = VoiPassPeriodUnitType.Day

export const voiPassNameOptions = [
    { label: periodDayPassName, value: periodDayPassName },
    { label: freeUnlockDayPassName, value: freeUnlockDayPassName },
]

export interface VoiPassZoneOption {
    label: string
    value: string
}
export const voiPassZoneOptions = (currentZone: Zone, allZones: Zone[], chosenZones: Zone[]): VoiPassZoneOption[] => {
    // return zones with the same currency as default zone, and that hasn't been chosen
    return allZones
        .filter(z => isValidZoneOption(z, currentZone, chosenZones))
        .map(z => ({
            label: z.name,
            value: z.zone_id,
        }))
}

export const mapZoneIdsToNamesStr = (zones: Zone[], zoneIds: string[]) => {
    return zones
        .filter(zone => zoneIds.includes(zone.zone_id))
        .map(zone => zone.name)
        .join(', ')
}

const isValidZoneOption = (zone: Zone, currentZone: Zone, chosenZones: Zone[]): boolean => {
    const currentZoneCurrency = currentZone.currency
    // extract all chosen zone IDs
    const chosenZoneIds = chosenZones.map(z => z.zone_id)
    return (
        zone.currency === currentZoneCurrency &&
        zone.zone_id !== currentZone.zone_id &&
        !chosenZoneIds.includes(zone.zone_id)
    )
}
