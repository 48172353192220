import { TableFooter } from 'src/ui-kit/table/Table'

export interface Props {
    breakpoint: number
    currentRows: number
    expanded: boolean
    setExpanded: (newState: boolean) => void
}

function ExpandTableFooter({ breakpoint, currentRows, expanded, setExpanded }: Props) {
    if (currentRows <= breakpoint) {
        return null
    }

    return expanded ? (
        <TableFooter onClick={() => setExpanded(false)} data-testid='expand-table-footer-toggle-show-less'>
            SHOW LESS ▴
        </TableFooter>
    ) : (
        <TableFooter onClick={() => setExpanded(true)} data-testid='expand-table-footer-toggle-show-more'>
            SHOW MORE ▾
        </TableFooter>
    )
}

export default ExpandTableFooter
