import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError, isNotFoundError } from 'src/api/utils'
import {
    type AllVehiclesPricing,
    type GetVoiPassGroupsResponse,
    type GetVehiclePricingResponse,
    type GetVehiclePricingResponseItem,
    type VehiclePricingItem,
    VehiclePricingPricePartType,
    type VehiclePricingTimeslotType,
    VehiclePricingType,
    type VehiclePricingWeekday,
    type VoiPassDiscount,
    type VoiPassGroup,
    type UpdateVoiPassDiscountRequest,
} from 'src/api/fm/pricing/pricing.model'
import { type RiderDiscount, type RiderDiscountGroup } from 'src/redux/rider/rider.types'

export const getDiscountGroups = async (): Promise<RiderDiscountGroup[] | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/groups')
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getRiderDiscount = async (userId: string): Promise<RiderDiscount | Error> => {
    const link = encodeURI(`/v1/fm/pricing/ride-plans/user-groups/user/${userId}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => {
            if (!isNotFoundError(e)) {
                return getError(e, 'response.data.errors[0]')
            }

            return undefined
        })
}

export const addRiderDiscount = async (data: {
    group_id: string
    user_id: string
    valid_from: number
    valid_to: number | null
}): Promise<RiderDiscount | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/user-groups')
    const headers = await getHeaders()
    return ins
        .post(link, data, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const updateRiderDiscount = async (data: RiderDiscount): Promise<RiderDiscount | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/user-groups')
    const headers = await getHeaders()
    return ins
        .put(link, data, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const removeRiderDiscount = async (groupId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/pricing/ride-plans/user-groups/${groupId}`)
    const headers = await getHeaders()
    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/pricing%20gateway/getPrices API documentation}
 */
export const getVehiclePricing = async (zoneId: string): Promise<AllVehiclesPricing | Error> => {
    const link = encodeURI(`/v1/fm/pricing/prices/${zoneId}`)
    const headers = await getHeaders()
    return ins
        .get<GetVehiclePricingResponse>(link, { headers })
        .then(res => {
            if (res.status === 204) {
                return {
                    [VehiclePricingType.Scooter]: [],
                    [VehiclePricingType.EBike]: [],
                }
            }
            return mapGetVehiclePricing(res.data)
        })
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/pricing%20gateway/updatePrices API documentation}
 */
export const editVehiclePricing = async (
    pricingGroups: VehiclePricingItem[],
): Promise<VehiclePricingItem[] | Error> => {
    const link = encodeURI('/v1/fm/pricing/prices')
    const headers = await getHeaders()
    return ins
        .put(link, { data: pricingGroups }, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/pricing%20gateway/getRidePlans API documentation}
 */
export const getVoiPassGroups = async (zoneId: string) => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/plans')
    const params = { zoneId }
    const headers = await getHeaders()

    return ins
        .get<GetVoiPassGroupsResponse>(link, { headers, params })
        .then(res => {
            if (res.status === 204) {
                return []
            }
            return mapGetVoiPassGroups(res.data)
        })
        .catch(e => {
            if (!isNotFoundError(e)) {
                return getError(e, 'response.data.errors[0]')
            }

            return undefined
        })
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/pricing%20gateway/createRidePlan API documentation}
 */
export const createVoiPassGroup = async (voiPass: VoiPassGroup): Promise<void | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/plans')
    const headers = await getHeaders()
    return ins
        .post(link, voiPass, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/pricing%20gateway/updateRidePlans API documentation}
 */
export const editVoiPassGroup = async (voiPass: VoiPassGroup): Promise<void | Error> => {
    const link = encodeURI('/v2/fm/pricing/ride-plans/plans')
    const headers = await getHeaders()
    return ins
        .put(link, voiPass, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/pricing%20gateway/createRidePlanDiscount API documentation}
 */
export const createVoiPassDiscount = async (discount: VoiPassDiscount): Promise<void | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/discount')
    const headers = await getHeaders()
    return ins
        .post(link, discount, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/pricing%20gateway/getRidePlanDiscount API documentation}
 */
export const getVoiPassDiscount = async (discountId: string): Promise<VoiPassDiscount | Error> => {
    const link = encodeURI(`/v1/fm/pricing/ride-plans/discount/${discountId}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const updateVoiPassDiscount = async (
    discount: UpdateVoiPassDiscountRequest,
): Promise<VoiPassDiscount | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/discount')
    const headers = await getHeaders()
    return ins
        .put(link, discount, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetVehiclePricing = (response: GetVehiclePricingResponse): AllVehiclesPricing => {
    const scooterItems: GetVehiclePricingResponseItem[] = response.data[VehiclePricingType.Scooter]
    const bikeItems: GetVehiclePricingResponseItem[] = response.data[VehiclePricingType.EBike]

    const itemMapFn = (responseItem: GetVehiclePricingResponseItem): VehiclePricingItem => ({
        id: responseItem.id,
        zoneId: responseItem.zoneId,
        timeZoneLocation: responseItem.timeZoneLocation,
        timeslot: responseItem.timeslot as VehiclePricingTimeslotType,
        timeslotStart: responseItem.timeslotStart,
        timeslotEnd: responseItem.timeslotEnd,
        vehicleCategory: responseItem.vehicleCategory as VehiclePricingType,
        weekday: responseItem.weekday as VehiclePricingWeekday,
        priceParts: {
            [VehiclePricingPricePartType.PerMinuteCost]:
                responseItem.priceParts[VehiclePricingPricePartType.PerMinuteCost],
            [VehiclePricingPricePartType.UnlockFee]: responseItem.priceParts[VehiclePricingPricePartType.UnlockFee],
        },
    })

    return {
        [VehiclePricingType.Scooter]: scooterItems.map(itemMapFn),
        [VehiclePricingType.EBike]: bikeItems.map(itemMapFn),
    }
}

const mapGetVoiPassGroups = (response: GetVoiPassGroupsResponse): VoiPassGroup[] => {
    if (response.data) {
        return response.data.map(item => ({
            active: item.active,
            currency: item.currency,
            description: item.description,
            discountAmount: item.discountAmount,
            billingCyclesCount: item.billingCyclesCount,
            discountId: item.discountId,
            id: item.id,
            minutesIncluded: item.minutesIncluded,
            name: item.name,
            period: item.period,
            periodUnit: item.periodUnit,
            price: item.price,
            promotionCategory: item.promotionCategory,
            recurrent: item.recurrent,
            validFrom: item.validFrom,
            validTo: item.validTo,
            zones: item.zones,
            rideZoneIds: item.rideZoneIds,
            uuidMask: item.uuidMask,
        }))
    }

    return []
}
