export interface GetRideHistoryResponse {
    data: RideHistoryResponseItem[]
}

export interface GetRideResponse {
    data: RideHistoryResponseItem
}

export interface RideHistoryResponseItem {
    auto_ended: string
    cost: number
    credit: number
    currency: string
    discount: number
    end_address: string
    end_time: number
    manual_ended: boolean
    parking_photo: boolean
    parking_review?: {
        assessment: AssessmentItems | null
        decision: string
        review_completed_at: number
        reviewed_by: string
        abandoned_ride: boolean
    }
    payment_method_brand: string
    payment_method_info: string
    per_minute_cost: number
    receipt_link: string
    rental_id: string
    start_address: string
    start_time: number
    user_id: string
    vehicle_code: string
    zone_id: string
    start_location?: RideHistoryLocation
    end_location?: RideHistoryLocation
}

export interface AssessmentItems {
    bad_parking_reasons: BAD_PARKING_REASONS[]
    bad_photo_reasons: BAD_PHOTO_REASONS[]
    parking_quality: PARKING_ASSISTANT_PARKING_QUALITY
    photo_quality: PARKING_ASSISTANT_PHOTO_QUALITY
}

export interface RideHistoryItem {
    autoEndedLabel: string
    cost: number
    credit: number
    currency: string
    discount: number
    endAddress: string
    endTime: number
    isManuallyEnded: boolean
    hasParkingPhoto: boolean
    parkingReview?: ParkingReview
    paymentMethodBrand: string
    paymentMethodInfo: string
    perMinuteCost: number
    receiptLink: string
    rentalId: string
    startAddress: string
    startTime: number
    userId: string
    vehicleCode: string
    zoneId: string
    startLocation?: RideHistoryLocation
    endLocation?: RideHistoryLocation
}

export interface RideHistoryLocation {
    latitude: number
    longitude: number
}

export interface ParkingReview {
    assessment: AssessmentItems | null
    decision: ParkingPhotoDecision
    abandonedRide: boolean
    reviewCompletedAt: number
    reviewedBy: string
}

export interface Ride {
    first_lock_attempt: number
    start_time: number
    user_id: string
    vehicle_code: string
    zone_id: string
    zone_name: string
    lock_count: number
    reserved: boolean
    start_lock: Record<string, any>
    duration: any // FIXME: Deprecate in favour of minute_duration
    minute_duration: number
    rental_id: string
    source: string
    ride_type: string
}

export enum PARKING_ASSISTANT_PHOTO_QUALITY {
    PHOTO_GOOD = 'PHOTO_GOOD',
    PHOTO_BAD = 'PHOTO_BAD',
    PHOTO_DARK = 'PHOTO_DARK',
    PHOTO_UNKNOWN = 'PHOTO_UNKNOWN',
}

export enum PARKING_ASSISTANT_PARKING_QUALITY {
    PARKING_GOOD = 'PARKING_GOOD',
    PARKING_NOT_IDEAL = 'PARKING_NOT_IDEAL',
    PARKING_BAD = 'PARKING_BAD',
    PARKING_UNKNOWN = 'PARKING_UNKNOWN',
}

export enum BAD_PARKING_REASONS {
    BAD_PARKING_OTHER = 'BAD_PARKING_OTHER',
    BAD_PARKING_BLOCKING_PAVEMENT = 'BAD_PARKING_BLOCKING_PAVEMENT',
    BAD_PARKING_BLOCKING_ACCESS = 'BAD_PARKING_BLOCKING_ACCESS',
    BAD_PARKING_BLOCKING_TRAFFIC = 'BAD_PARKING_BLOCKING_TRAFFIC',
    BAD_PARKING_NOT_UPRIGHT = 'BAD_PARKING_NOT_UPRIGHT',
}

export enum BAD_PHOTO_REASONS {
    BAD_PHOTO_OTHER = 'BAD_PHOTO_OTHER',
    BAD_PHOTO_DARK = 'BAD_PHOTO_DARK',
    BAD_PHOTO_BLURRY = 'BAD_PHOTO_BLURRY',
    BAD_PHOTO_NOT_WHOLE_SCOOTER = 'BAD_PHOTO_NOT_WHOLE_SCOOTER',
    BAD_PHOTO_NO_PHOTO = 'BAD_PHOTO_NO_PHOTO',
}

export const PARKING_ASSISTANT_REASONS_DESCRIPTION = {
    [BAD_PARKING_REASONS.BAD_PARKING_BLOCKING_ACCESS]: 'Blocking access',
    [BAD_PARKING_REASONS.BAD_PARKING_BLOCKING_PAVEMENT]: 'Blocking pavement',
    [BAD_PARKING_REASONS.BAD_PARKING_BLOCKING_TRAFFIC]: 'Blocking traffic',
    [BAD_PARKING_REASONS.BAD_PARKING_NOT_UPRIGHT]: 'Not upright',
    [BAD_PARKING_REASONS.BAD_PARKING_OTHER]: 'Other',
    [BAD_PHOTO_REASONS.BAD_PHOTO_BLURRY]: 'Blurry',
    [BAD_PHOTO_REASONS.BAD_PHOTO_DARK]: 'Dark',
    [BAD_PHOTO_REASONS.BAD_PHOTO_NOT_WHOLE_SCOOTER]: 'Not whole scooter',
    [BAD_PHOTO_REASONS.BAD_PHOTO_OTHER]: 'Other',
    [BAD_PHOTO_REASONS.BAD_PHOTO_NO_PHOTO]: 'No photo',
}

export enum ParkingPhotoDecision {
    GOOD_PARKING = 'GOOD_PARKING',
    NOT_IDEAL_PARKING = 'NOT_IDEAL_PARKING',
    UNCLEAR_PARKING = 'UNCLEAR_PARKING',
    BAD_PHOTO_FINE = 'BAD_PHOTO_FINE',
    BAD_PHOTO_WARN = 'BAD_PHOTO_WARN',
    BAD_PHOTO_NOTIFY = 'BAD_PHOTO_NOTIFY',
    BAD_PHOTO_TOO_DARK = 'BAD_PHOTO_TOO_DARK',
    BAD_PARKING_FINE = 'BAD_PARKING_FINE',
    BAD_PARKING_WARN = 'BAD_PARKING_WARN',
    UNDEFINED = 'UNDEFINED',
}
