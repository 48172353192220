import { type ViolationsActions, type ViolationsState } from 'src/redux/violations/violations.types'

export const INITIAL_STATE: ViolationsState = {
    missingConfigs: [],
    missingProperties: [],
}

export function violationsReducer(state: ViolationsState = INITIAL_STATE, action: ViolationsActions): ViolationsState {
    switch (action.type) {
        case 'SET_VIOLATIONS':
            return {
                ...state,
                missingConfigs: action.payload.missingConfigs,
                missingProperties: action.payload.missingProperties,
            }
        default:
            return state
    }
}
