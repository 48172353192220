import { call, put, type SagaReturnType, takeEvery } from 'redux-saga/effects'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import {
    createAccidentReport,
    createAccidentReason as createAccidentReasonApi,
    editAccidentReason as editAccidentReasonApi,
    deleteAccidentReason as deleteAccidentReasonApi,
    getAccidentReasons as getAccidentReasonsApi,
    deleteAccidentReport,
    editAccidentReport,
    getAccidentReportById,
    getAccidentReports,
} from 'src/api'
import {
    setAccidentReasons,
    setAccidentReports,
    setCurrentAccidentReport,
    getAccidentReasons,
} from 'src/redux/accidents/accidents.actions'
import {
    CREATE_ACCIDENT_REPORT,
    DELETE_ACCIDENT_REPORT,
    EDIT_ACCIDENT_REPORT,
    GET_ACCIDENT_REPORT,
    GET_ACCIDENT_REPORTS,
    GET_ACCIDENT_REASONS,
    CREATE_ACCIDENT_REASON,
    EDIT_ACCIDENT_REASON,
    DELETE_ACCIDENT_REASON,
    type GetAccidentReport,
    type CreateAccidentReport,
    type EditAccidentReport,
    type DeleteAccidentReport,
    type GetAccidentReports,
    type CreateAccidentReason,
    type DeleteAccidentReason,
    type EditAccidentReason,
} from 'src/redux/accidents/accidents.types'

type GetAccidentReportsRes = SagaReturnType<typeof getAccidentReports>

export function* fetchAccidentReports(action: GetAccidentReports) {
    const { limit, offset, query } = action.payload
    const reports: GetAccidentReportsRes = yield call(getAccidentReports, limit, offset, query)

    if (reports instanceof Error) {
        yield call(notifyUser, reports, 'error')
        return
    }

    yield put(setAccidentReports(reports))
}

type GetAccidentReportByIdRes = SagaReturnType<typeof getAccidentReportById>

export function* fetchAccidentReport(action: GetAccidentReport) {
    const reportId = action.payload
    const report: GetAccidentReportByIdRes = yield call(getAccidentReportById, reportId)

    if (report instanceof Error) {
        yield call(notifyUser, report, 'error')
        return
    }

    yield put(setCurrentAccidentReport(report))
}

type CreateAccidentReportRes = SagaReturnType<typeof createAccidentReport>

export function* addAccidentReport(action: CreateAccidentReport) {
    const { reportForm, pushToRoute } = action.payload
    const resultId: CreateAccidentReportRes = yield call(createAccidentReport, reportForm)

    if (resultId instanceof Error) {
        yield call(notifyUser, resultId, 'error')
        return
    }

    yield call(notifyUser, 'Created Accident Report', 'success')
    yield call(pushToRoute, resultId)
}

type EditAccidentReportRes = SagaReturnType<typeof editAccidentReport>

export function* updateAccidentReport(action: EditAccidentReport) {
    const { reportId, reportForm, pushToRoute } = action.payload
    const resultId: EditAccidentReportRes = yield call(editAccidentReport, reportId, reportForm)

    if (resultId instanceof Error) {
        yield call(notifyUser, resultId, 'error')
        return
    }

    yield call(notifyUser, 'Updated Accident Report', 'success')
    yield call(pushToRoute, resultId)
}

type DeleteAccidentReportRes = SagaReturnType<typeof deleteAccidentReport>

export function* removeAccidentReport(action: DeleteAccidentReport) {
    const { reportId, pushToRoute } = action.payload
    const result: DeleteAccidentReportRes = yield call(deleteAccidentReport, reportId)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Deleted Accident Report', 'success')
    yield call(pushToRoute)
}

type GetAccidentReasonsRes = SagaReturnType<typeof getAccidentReasonsApi>

export function* fetchAccidentReasons() {
    const reasons: GetAccidentReasonsRes = yield call(getAccidentReasonsApi)

    if (reasons instanceof Error) {
        yield call(notifyUser, reasons, 'error')
        return
    }

    yield put(setAccidentReasons(reasons))
}

type CreateAccidentReasonRes = SagaReturnType<typeof createAccidentReasonApi>

export function* createAccidentReason(action: CreateAccidentReason) {
    const response: CreateAccidentReasonRes = yield call(createAccidentReasonApi, action.payload)

    if (response instanceof Error) {
        yield call(notifyUser, response, 'error')
        return
    }

    yield put(getAccidentReasons())
    yield call(notifyUser, 'Created Accident Reason', 'success')
}

type EditAccidentReasonRes = SagaReturnType<typeof editAccidentReasonApi>

export function* editAccidentReason(action: EditAccidentReason) {
    const { reasonId, reason } = action.payload
    const response: EditAccidentReasonRes = yield call(editAccidentReasonApi, reasonId, reason)

    if (response instanceof Error) {
        yield call(notifyUser, response, 'error')
        return
    }

    yield put(getAccidentReasons())
    yield call(notifyUser, 'Updated Accident Reason', 'success')
}

type DeleteAccidentReasonsRes = SagaReturnType<typeof deleteAccidentReasonApi>

export function* deleteAccidentReason(action: DeleteAccidentReason) {
    const response: DeleteAccidentReasonsRes = yield call(deleteAccidentReasonApi, action.payload.reasonId)

    if (response instanceof Error) {
        yield call(notifyUser, response, 'error')
        return
    }

    yield put(getAccidentReasons())
    yield call(notifyUser, 'Deleted Accident Reason', 'success')
}

export default function* watcher() {
    yield takeEvery(GET_ACCIDENT_REPORTS, fetchAccidentReports)
    yield takeEvery(GET_ACCIDENT_REPORT, fetchAccidentReport)
    yield takeEvery(GET_ACCIDENT_REASONS, fetchAccidentReasons)
    yield takeEvery(CREATE_ACCIDENT_REASON, createAccidentReason)
    yield takeEvery(EDIT_ACCIDENT_REASON, editAccidentReason)
    yield takeEvery(DELETE_ACCIDENT_REASON, deleteAccidentReason)
    yield takeEvery(CREATE_ACCIDENT_REPORT, addAccidentReport)
    yield takeEvery(EDIT_ACCIDENT_REPORT, updateAccidentReport)
    yield takeEvery(DELETE_ACCIDENT_REPORT, removeAccidentReport)
}
