import { CREATE_NOTE, DELETE_NOTE, GET_NOTES, SET_NOTES } from 'src/redux/note/note.types'

export const createNote = (payload: Record<string, any>) => {
    return {
        type: CREATE_NOTE,
        payload,
    }
}

export const deleteNote = (payload: { noteId: string; id: string }) => ({
    type: DELETE_NOTE,
    payload,
})

export const setNotes = (payload: Record<string, any>[] | null) => ({
    type: SET_NOTES,
    payload,
})

export const getNotes = (payload: string) => ({
    type: GET_NOTES,
    payload,
})
