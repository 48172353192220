import {
    CREATE_HUNTER_IN_ORGANIZATION,
    DELETE_ORGANIZATION,
    GET_ORGANIZATION,
    SET_CURRENT_ORGANIZATION,
    GET_ORGANIZATION_HISTORY_TASKS,
    SET_ORGANIZATION_HISTORY_TASKS,
    CREATE_ORGANIZATION,
    SET_ORGANIZATION_HISTORY_FILTER,
    INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM,
    SET_END_OF_PAGINATION,
    GET_NEXT_HISTORY_PAGE,
    EDIT_ORGANIZATION,
    EDIT_ORGANIZATION_SUCCEEDED,
    CREATE_ORGANIZATION_SUCCEEDED,
    DELETE_ORGANIZATION_SUCCEEDED,
    type OrganizationHistoryFilters,
    type Organization,
    type SetCurrentOrganization,
    type SetOrganizationHistoryTasks,
    type SetOrganizationHistoryFilter,
    type IncrementOrganizationHistoryPageNum,
    type CreateHunterInOrganization,
    type EditOrganization,
    type DeleteOrganization,
    type GetOrganization,
    type CreateOrganization,
    type SetIsFetchingOrganizationHistoryTasks,
    SET_IS_FETCHING_ORGANIZATION_HISTORY_TASKS,
    type OrganizationAllowedArea,
    type CreateOrganizationAllowedArea,
    CREATE_ORGANIZATION_ALLOWED_AREA,
    type EditOrganizationAllowedArea,
    EDIT_ORGANIZATION_ALLOWED_AREA,
    DELETE_ORGANIZATION_ALLOWED_AREA,
    type DeleteOrganizationAllowedArea,
    type GetOrganizationAllowedAreas,
    GET_ORGANIZATION_ALLOWED_AREAS,
    type SetOrganizationAllowedAreas,
    SET_ORGANIZATION_ALLOWED_AREAS,
} from 'src/redux/organization/organization.types'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import { type Hunter } from 'src/redux/hunter/hunter.types'
import { type NavigateFunction } from 'react-router-dom'

export function editOrganization(org: Organization): EditOrganization {
    return {
        type: EDIT_ORGANIZATION,
        payload: org,
    }
}

export function createHunterInOrganization(payload: Omit<Hunter, 'accountId'>): CreateHunterInOrganization {
    return {
        type: CREATE_HUNTER_IN_ORGANIZATION,
        payload,
    }
}

export function deleteOrganization(orgId: string, navigateFn: NavigateFunction): DeleteOrganization {
    return {
        type: DELETE_ORGANIZATION,
        payload: { orgId, navigateFn },
    }
}

export function getOrganization(orgId: string): GetOrganization {
    return {
        type: GET_ORGANIZATION,
        payload: orgId,
    }
}

export function setCurrentOrganization(org: Organization | null): SetCurrentOrganization {
    return {
        type: SET_CURRENT_ORGANIZATION,
        payload: org,
    }
}

export function getOrganizationHistoryTasks() {
    return {
        type: GET_ORGANIZATION_HISTORY_TASKS,
    }
}

export function setOrganizationHistoryTasks(tasks: Task[]): SetOrganizationHistoryTasks {
    return {
        type: SET_ORGANIZATION_HISTORY_TASKS,
        payload: tasks,
    }
}

export function setIsFetchingOrganizationHistoryTasks(isFetching: boolean): SetIsFetchingOrganizationHistoryTasks {
    return {
        type: SET_IS_FETCHING_ORGANIZATION_HISTORY_TASKS,
        payload: isFetching,
    }
}

export function createOrganization({
    organization,
    pushToRoute,
}: {
    organization: Organization
    pushToRoute: (id: string | undefined) => void
}): CreateOrganization {
    return {
        type: CREATE_ORGANIZATION,
        payload: { organization, pushToRoute },
    }
}

export function getOrganizationAllowedAreas(orgId: string): GetOrganizationAllowedAreas {
    return {
        type: GET_ORGANIZATION_ALLOWED_AREAS,
        payload: orgId,
    }
}

export function setOrganizationAllowedAreas(allowedAreas: OrganizationAllowedArea[]): SetOrganizationAllowedAreas {
    return {
        type: SET_ORGANIZATION_ALLOWED_AREAS,
        payload: allowedAreas,
    }
}

export function createOrganizationAllowedArea(allowedArea: OrganizationAllowedArea): CreateOrganizationAllowedArea {
    return {
        type: CREATE_ORGANIZATION_ALLOWED_AREA,
        payload: allowedArea,
    }
}

export function editOrganizationAllowedArea(allowedArea: OrganizationAllowedArea): EditOrganizationAllowedArea {
    return {
        type: EDIT_ORGANIZATION_ALLOWED_AREA,
        payload: allowedArea,
    }
}

export function deleteOrganizationAllowedArea(allowedArea: OrganizationAllowedArea): DeleteOrganizationAllowedArea {
    return {
        type: DELETE_ORGANIZATION_ALLOWED_AREA,
        payload: allowedArea,
    }
}

export function setOrganizationHistoryFilter(filters: OrganizationHistoryFilters): SetOrganizationHistoryFilter {
    return {
        type: SET_ORGANIZATION_HISTORY_FILTER,
        payload: filters,
    }
}

export function incrementOrganizationHistoryPageNum(): IncrementOrganizationHistoryPageNum {
    return {
        type: INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM,
    }
}

export function setEndOfPagination() {
    return {
        type: SET_END_OF_PAGINATION,
    }
}

export function getNextHistoryPage() {
    return {
        type: GET_NEXT_HISTORY_PAGE,
    }
}

export function editOrganizationSucceeded(editedOrganization: Organization) {
    return {
        type: EDIT_ORGANIZATION_SUCCEEDED,
        payload: editedOrganization,
    }
}

export function createOrganizationSucceeded(newOrganization: Organization) {
    return {
        type: CREATE_ORGANIZATION_SUCCEEDED,
        payload: newOrganization,
    }
}

export function deleteOrganizationSucceeded(organizationId: string) {
    return {
        type: DELETE_ORGANIZATION_SUCCEEDED,
        payload: organizationId,
    }
}
