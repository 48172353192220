import {
    SET_VEHICLE_MODELS,
    type VehicleModelsActions,
    type VehicleModelState,
} from 'src/redux/vehicleModel/vehicleModel.types'

export const INITIAL_STATE: VehicleModelState = {
    vehicleModels: [],
}

export const vehicleModelReducer = (state = INITIAL_STATE, action: VehicleModelsActions): VehicleModelState => {
    switch (action.type) {
        case SET_VEHICLE_MODELS:
            return {
                ...state,
                vehicleModels: action.payload,
            }
        default:
            return state
    }
}
