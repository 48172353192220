import type { Violations } from 'src/api/confighub/violations/violations.model'
import { SET_VIOLATIONS } from 'src/redux/violations/violations.types'

export const getViolationsAction = () => ({
    type: 'GET_VIOLATIONS',
})

export const setViolationsAction = (payload: Violations) => ({
    type: SET_VIOLATIONS,
    payload,
})
