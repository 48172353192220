import { searchRider, getRider } from 'src/api'
import type { Rider } from 'src/api/fm/users/users.model'

export async function searchRiderByEmailOrId(searchTerm: string): Promise<Rider[] | Error> {
    // If search term includes '@' it's not a valid uuid but an email
    if (searchTerm.includes('@')) {
        const emailRes = await searchRider(searchTerm)

        if (emailRes instanceof Error) {
            return emailRes
        } else {
            if (emailRes.data.data.length > 0) {
                return emailRes.data.data
            } else {
                return new Error('Rider not found')
            }
        }
    } else {
        const idRes = await getRider(searchTerm)

        if (idRes instanceof Error) {
            return idRes
        } else {
            return [idRes]
        }
    }
}
