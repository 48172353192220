import { jwtDecode } from 'jwt-decode'
import { AUTH_TOKEN_URL, TOKEN_RESERVED_EXP_TIME } from 'src/constants/auth'
import { firebase } from 'src/firebase'
import { ins } from 'src/api/axios'

export interface FirebaseJWT {
    exp: number
    userId: string
    role: {
        global: string[]
        zone: Record<string, string[]>
    }
}

export interface TokensBundle {
    accessToken: string
    accessTokenParsed: FirebaseJWT
}

export class AuthFirebaseError extends Error {}
export class AuthTokenRequestError extends Error {}
export class AuthTokenParseError extends Error {}

export class AuthManager {
    #accessToken: string | null = null
    #accessTokenParsed: FirebaseJWT | null = null
    #updateTokenPromise: Promise<TokensBundle> | null = null

    getTokens = (): Promise<TokensBundle> => {
        // If token is already being updated, wait for the request to finish
        if (this.#updateTokenPromise) {
            return this.#updateTokenPromise
        }
        // If token is expired or does not exist, try to get a new one
        else if (this.#isTokenExpired()) {
            return this.#updateTokens()
        }

        const formattedTokens = this.#formatTokens()
        if (!formattedTokens) {
            return Promise.reject(new AuthTokenParseError())
        } else {
            return Promise.resolve(formattedTokens)
        }
    }

    clearTokens = () => {
        this.#accessToken = null
        this.#accessTokenParsed = null
        this.#updateTokenPromise = null
    }

    #formatTokens = (): TokensBundle | null => {
        if (!this.#accessToken || !this.#accessTokenParsed) {
            return null
        }

        return {
            accessToken: this.#accessToken,
            accessTokenParsed: this.#accessTokenParsed,
        }
    }

    #isTokenExpired = () => {
        if (!this.#accessTokenParsed) {
            return true
        }

        // JWT contains time in seconds, so convert current time to seconds too
        const now = Math.ceil(Date.now() / 1000)
        const expiresIn = this.#accessTokenParsed.exp - now - TOKEN_RESERVED_EXP_TIME

        return expiresIn < 0
    }

    #updateTokens = () => {
        this.#updateTokenPromise = firebase.auth
            .currentUser!.getIdToken()
            .catch(() => Promise.reject(new AuthFirebaseError()))
            .then(idToken => {
                return new Promise<TokensBundle>((resolve, reject) => {
                    ins.post(AUTH_TOKEN_URL, { idToken }, { headers: { 'Content-Type': 'application/json' } })
                        .then(res => {
                            try {
                                const accessToken = res.data.accessToken
                                const accessTokenParsed = jwtDecode<FirebaseJWT>(accessToken)

                                // Update token fields only after successful decoding
                                this.#accessToken = accessToken
                                this.#accessTokenParsed = accessTokenParsed

                                const formattedTokens = this.#formatTokens()
                                if (!formattedTokens) {
                                    reject(new AuthTokenParseError())
                                } else {
                                    resolve(formattedTokens)
                                }
                            } catch (e) {
                                reject(new AuthTokenParseError())
                            }
                        })
                        .catch(() => {
                            reject(new AuthTokenRequestError())
                        })
                })
            })
            .finally(() => {
                this.#updateTokenPromise = null
            })

        return this.#updateTokenPromise
    }
}

export const auth = new AuthManager()
