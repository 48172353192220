import {
    SET_ACTIVE_RIDE_RIDER,
    SET_GUEST_RIDES,
    SET_ACCOUNT_SHARING_INFO,
    TOGGLE_END_RIDE_MODAL,
    SET_CURRENT_RIDER,
    SET_RIDER_RIDE_HISTORY,
    RESET_RIDER_RIDE_HISTORY,
    SET_RIDER_RIDE_HISTORY_DATE_FILTER,
    SET_DISCOUNT_GROUPS,
    SET_RIDER_DISCOUNT,
    SET_RIDE_PARKING_PHOTO,
    SET_DRIVERS_LICENSE_STATUS,
    SET_RIDER_VOI_PASS,
    SET_CURRENT_RIDE_HISTORY_ITEM,
    SET_RIDER_VOUCHER_DETAILS,
    type RiderState,
    type RiderActions,
    RESET_RIDER_FINE_HISTORY,
    SET_RIDER_FINE_HISTORY,
    SET_RIDER_PAYMENT_DETAILS,
    SET_DELETE_USER_FAILED,
} from 'src/redux/rider/rider.types'

export const INITIAL_STATE: RiderState = {
    endRideModal: null,
    currentRider: null,
    rideHistory: null,
    currentRideHistoryItem: null,
    parkingPhoto: null,
    rideHistoryDateFilter: null,
    rideHistorySourceFilter: null,
    activeRide: null,
    guestRides: null,
    accountSharingInfo: null,
    discountGroups: null,
    riderDiscount: null,
    voiPass: null,
    paymentDetails: null,
    driversLicenseStatus: null,
    voucherDetails: {
        canUseVouchers: undefined,
        vouchers: [],
    },
    fineHistory: null,
    deleteUserFailed: false,
}

export function riderReducer(state: RiderState = INITIAL_STATE, action: RiderActions): RiderState {
    switch (action.type) {
        case TOGGLE_END_RIDE_MODAL:
            return { ...state, endRideModal: action.payload }

        case SET_CURRENT_RIDER:
            return { ...state, currentRider: action.payload }

        case SET_RIDER_RIDE_HISTORY:
            return { ...state, rideHistory: action.payload }

        case SET_CURRENT_RIDE_HISTORY_ITEM:
            return { ...state, currentRideHistoryItem: action.payload }

        case SET_RIDE_PARKING_PHOTO:
            return { ...state, parkingPhoto: action.payload }

        case RESET_RIDER_RIDE_HISTORY:
            return { ...state, rideHistory: null, rideHistoryDateFilter: null }

        case SET_RIDER_RIDE_HISTORY_DATE_FILTER:
            return { ...state, rideHistoryDateFilter: action.payload }

        case SET_ACTIVE_RIDE_RIDER:
            return { ...state, activeRide: action.payload }

        case SET_GUEST_RIDES:
            return { ...state, guestRides: action.payload }

        case SET_ACCOUNT_SHARING_INFO:
            return { ...state, accountSharingInfo: action.payload }

        case SET_DISCOUNT_GROUPS:
            return { ...state, discountGroups: action.payload }

        case SET_RIDER_DISCOUNT:
            return { ...state, riderDiscount: action.payload }

        case SET_RIDER_VOI_PASS:
            return { ...state, voiPass: action.payload }

        case SET_RIDER_PAYMENT_DETAILS:
            return { ...state, paymentDetails: action.payload }

        case SET_DRIVERS_LICENSE_STATUS:
            return { ...state, driversLicenseStatus: action.payload }

        case SET_RIDER_VOUCHER_DETAILS:
            return { ...state, voucherDetails: action.payload }

        case SET_RIDER_FINE_HISTORY:
            return { ...state, fineHistory: action.payload }

        case RESET_RIDER_FINE_HISTORY:
            return { ...state, fineHistory: null }

        case SET_DELETE_USER_FAILED:
            return { ...state, deleteUserFailed: action.payload }

        default:
            return state
    }
}
