export const SET_NAV_EXPANDED = 'SET_NAV_EXPANDED'
export const EXPAND_ZONE = 'EXPAND_ZONE'
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV'
export const EXPAND_REPAIRS = 'EXPAND_REPAIRS'
export const EXPAND_RIDERS = 'EXPAND_RIDERS'
export const EXPAND_VEHICLES = 'EXPAND_VEHICLES'

export interface NavState {
    zoneExpanded: boolean
    navOpen: boolean
    mobileNavOpen: boolean
    repairsExpanded: boolean
    ridersExpanded: boolean
    vehiclesExpanded: boolean
}

export interface ExpandZone {
    type: typeof EXPAND_ZONE
    payload: boolean
}

export interface SetNavExpanded {
    type: typeof SET_NAV_EXPANDED
    payload: boolean
}

export interface ToggleMobileNav {
    type: typeof TOGGLE_MOBILE_NAV
    payload: boolean
}

export interface ExpandRepairs {
    type: typeof EXPAND_REPAIRS
    payload: boolean
}

export interface ExpandRiders {
    type: typeof EXPAND_RIDERS
    payload: boolean
}

export interface ExpandVehicles {
    type: typeof EXPAND_VEHICLES
    payload: boolean
}

export type NavActions = ExpandZone | SetNavExpanded | ToggleMobileNav | ExpandRepairs | ExpandRiders | ExpandVehicles
