import {
    BULK_CREATE_SCOOTER_TASKS,
    BULK_DELETE_SCOOTER_TASKS,
    BULK_UPDATE_SCOOTER_TASK_STATUS,
    BULK_UPDATE_SCOOTER_ZONE,
    BULK_UPDATE_TASK_PRIORITY,
    CLEAR_VEHICLE_BULK_LIST,
    CLEAR_VEHICLE_BULK_LIST_ERRORS,
    FETCH_BULK_LIST_VEHICLES,
    REMOVE_VEHICLES_FROM_BULK_LIST,
    REMOVE_REPAIR_VEHICLES_FROM_BULK_LIST,
    UPDATE_SCOOTERS_REMOVED_FROM_REPAIRS,
    SET_HAS_HANDLED_REPAIRS,
    SET_VEHICLE_BULK_LIST,
    SET_VEHICLE_BULK_LIST_ERRORS,
    SET_BULK_ACTION_LOADING,
    SET_BULK_ACTION_DONE,
    SET_BULK_LIST_FILTER,
    BULK_DEACTIVATE_LICENSE_PLATES,
    BULK_SET_DEACTIVATE_LICENSE_PLATES_RESULT,
    BULK_CLEAR_DEACTIVATE_LICENSE_PLATES_RESULT,
    BULK_DECOMMISSION_VEHICLES,
    SET_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
    INCREMENT_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
    CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
    SET_VEHICLES_NOT_FOUND,
    BULK_SELL_VEHICLES,
    BULK_UNDO_SELL_VEHICLES,
    BULK_UNDO_DECOMMISSION_VEHICLES,
    SET_INVALID_QRS,
    FETCH_BULK_LIST_BY_QUERY_QRS,
    SET_DUPLICATED_SEARCH_ITEMS,
} from 'src/redux/scooterBulk/scooterBulk.types'
import { type LicensePlatesDeactivationResult } from 'src/models/licensePlate'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'
import { type CloseTaskStatus, type ExecutionPriority } from 'src/api/fm/tasks/tasks.model'
import { type DecommissionReason } from 'src/api/fm/decommission/decommission.model'
import { type CreateTasksBulkPayload } from 'src/api/hunters/hunters.model'

export type GetScooterBulkAction<T extends ScooterBulkAction['type']> = Extract<ScooterBulkAction, { type: T }>

export type ScooterBulkAction =
    | ReturnType<typeof fetchBulkListVehicles>
    | ReturnType<typeof fetchBulkListByQueryQRs>
    | ReturnType<typeof setDuplicatedSearchItems>
    | ReturnType<typeof setInvalidQRs>
    | ReturnType<typeof clearVehicleBulkList>
    | ReturnType<typeof removeVehiclesFromBulkList>
    | ReturnType<typeof removeRepairVehiclesFromBulkList>
    | ReturnType<typeof setHasHandledRepairs>
    | ReturnType<typeof setVehicleBulkList>
    | ReturnType<typeof setVehiclesNotFound>
    | ReturnType<typeof setVehicleBulkListErrors>
    | ReturnType<typeof clearBulkListErrors>
    | ReturnType<typeof bulkCreateScooterTasks>
    | ReturnType<typeof bulkDeleteScooterTasks>
    | ReturnType<typeof bulkUpdateScooterTaskStatus>
    | ReturnType<typeof bulkUpdateScooterZone>
    | ReturnType<typeof bulkUpdateTaskExecutionPriority>
    | ReturnType<typeof bulkDeactivateLicensePlates>
    | ReturnType<typeof bulkSetDeactivateLicensePlatesResult>
    | ReturnType<typeof bulkClearDeactivateLicensePlatesResult>
    | ReturnType<typeof bulkDecommissionVehicles>
    | ReturnType<typeof bulkUndoDecommissionVehicles>
    | ReturnType<typeof updatedVehiclesRemovedFromRepairs>
    | ReturnType<typeof setBulkActionLoading>
    | ReturnType<typeof setBulkActionLoadingMultipleRequests>
    | ReturnType<typeof incrementBulkActionLoadingMultipleRequests>
    | ReturnType<typeof cancelBulkActionLoadingMultipleRequests>
    | ReturnType<typeof setBulkActionDone>
    | ReturnType<typeof setBulkListFilter>
    | ReturnType<typeof bulkSellVehicles>
    | ReturnType<typeof bulkUndoSellVehicles>

export const fetchBulkListVehicles = (ids: string[]) => {
    return {
        type: FETCH_BULK_LIST_VEHICLES,
        payload: ids,
    }
}

export const setDuplicatedSearchItems = (ids: string[]) => ({
    type: SET_DUPLICATED_SEARCH_ITEMS,
    payload: ids,
})

export const fetchBulkListByQueryQRs = (qrs: string) => ({
    type: FETCH_BULK_LIST_BY_QUERY_QRS,
    payload: qrs,
})

export const clearVehicleBulkList = () => {
    return {
        type: CLEAR_VEHICLE_BULK_LIST,
    }
}

export const removeVehiclesFromBulkList = (scooterIds: string[]) => {
    return {
        type: REMOVE_VEHICLES_FROM_BULK_LIST,
        payload: scooterIds,
    }
}

export const removeRepairVehiclesFromBulkList = () => {
    return {
        type: REMOVE_REPAIR_VEHICLES_FROM_BULK_LIST,
    }
}

export const setHasHandledRepairs = () => {
    return {
        type: SET_HAS_HANDLED_REPAIRS,
    }
}

export const setVehicleBulkList = (data: BulkListVehicle[]) => {
    return {
        type: SET_VEHICLE_BULK_LIST,
        payload: data,
    }
}

export const setVehiclesNotFound = (vehiclesNotFound: string[]) => ({
    type: SET_VEHICLES_NOT_FOUND,
    payload: vehiclesNotFound,
})

export const setVehicleBulkListErrors = (data: Record<string, string>) => {
    return {
        type: SET_VEHICLE_BULK_LIST_ERRORS,
        payload: data,
    }
}

export const setInvalidQRs = (qrs: string[]) => ({
    type: SET_INVALID_QRS,
    payload: qrs,
})

export const clearBulkListErrors = () => {
    return {
        type: CLEAR_VEHICLE_BULK_LIST_ERRORS,
    }
}

export const bulkCreateScooterTasks = (scooters: { id: string }[], task: CreateTasksBulkPayload) => {
    return {
        type: BULK_CREATE_SCOOTER_TASKS,
        payload: {
            scooters,
            task,
        },
    }
}

export const bulkDeleteScooterTasks = (scooters: { id: string }[]) => {
    return {
        type: BULK_DELETE_SCOOTER_TASKS,
        payload: { scooters },
    }
}

export const bulkUpdateScooterTaskStatus = (
    scooters: BulkListVehicle[],
    status: CloseTaskStatus,
    warehouseId: string | undefined,
) => {
    return {
        type: BULK_UPDATE_SCOOTER_TASK_STATUS,
        payload: {
            scooters,
            status,
            warehouseId,
        },
    }
}

export const bulkUpdateScooterZone = (scooters: { id: string }[], zone: string) => {
    return {
        type: BULK_UPDATE_SCOOTER_ZONE,
        payload: {
            scooters,
            zone,
        },
    }
}

export const bulkUpdateTaskExecutionPriority = (
    vehicles: BulkListVehicle[],
    taskIds: string[],
    executionPriority: ExecutionPriority,
) => {
    return {
        type: BULK_UPDATE_TASK_PRIORITY,
        payload: {
            vehicles,
            taskIds,
            executionPriority,
        },
    }
}

export const bulkDeactivateLicensePlates = (vehicles: BulkListVehicle[]) => {
    return {
        type: BULK_DEACTIVATE_LICENSE_PLATES,
        payload: vehicles,
    }
}

export const bulkSetDeactivateLicensePlatesResult = (result: LicensePlatesDeactivationResult) => {
    return {
        type: BULK_SET_DEACTIVATE_LICENSE_PLATES_RESULT,
        payload: result,
    }
}

export const bulkClearDeactivateLicensePlatesResult = () => {
    return {
        type: BULK_CLEAR_DEACTIVATE_LICENSE_PLATES_RESULT,
    }
}

export const bulkDecommissionVehicles = (vehicles: BulkListVehicle[], reason: DecommissionReason) => ({
    type: BULK_DECOMMISSION_VEHICLES,
    payload: { vehicles, reason },
})

export const bulkUndoDecommissionVehicles = (vehicles: BulkListVehicle[]) => ({
    type: BULK_UNDO_DECOMMISSION_VEHICLES,
    payload: vehicles,
})

export const bulkSellVehicles = (vehicles: BulkListVehicle[]) => ({
    type: BULK_SELL_VEHICLES,
    payload: vehicles,
})

export const bulkUndoSellVehicles = (vehicles: BulkListVehicle[]) => ({
    type: BULK_UNDO_SELL_VEHICLES,
    payload: vehicles,
})

export const updatedVehiclesRemovedFromRepairs = (scooterIds: string[]) => {
    return {
        type: UPDATE_SCOOTERS_REMOVED_FROM_REPAIRS,
        payload: { scooterIds },
    }
}

export const setBulkActionLoading = () => {
    return {
        type: SET_BULK_ACTION_LOADING,
    }
}

export const setBulkActionLoadingMultipleRequests = (totalRequests: number) => {
    return {
        type: SET_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
        payload: totalRequests,
    }
}

export const incrementBulkActionLoadingMultipleRequests = () => {
    return {
        type: INCREMENT_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
    }
}

export const cancelBulkActionLoadingMultipleRequests = () => {
    return {
        type: CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
    }
}

export const setBulkActionDone = () => {
    return {
        type: SET_BULK_ACTION_DONE,
    }
}

export const setBulkListFilter = (filter: string) => {
    return {
        type: SET_BULK_LIST_FILTER,
        payload: filter,
    }
}
