import { put, takeEvery } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { sortBy } from 'lodash-es'
import { collection, query, where, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { db } from 'src/firebase/firebase'
import {
    GET_NOTES,
    CREATE_NOTE,
    DELETE_NOTE,
    type GetNotes,
    type CreateNote,
    type DeleteNote,
} from 'src/redux/note/note.types'
import { getNotes as getNotesAction, setNotes } from 'src/redux/note/note.actions'

export function* getNotes(action: GetNotes) {
    try {
        const id = action.payload
        const notesQuery = query(collection(db, 'notes'), where('noteId', '==', id))
        const notes: Record<string, any> = yield getDocs(notesQuery)
        const notesList = notes ? notes.docs.map((doc: any) => doc.data()) : []
        let invalid: Record<string, any>[] = []
        let valid: Record<string, any>[] = []
        notesList.forEach((n: Record<string, any>) => {
            const date = new Date(n.date)
            if (isNaN(date.getTime())) {
                invalid.push(n)
            } else {
                valid.unshift(n)
            }
        })
        const sorted = sortBy(valid, o => o.date).reverse()
        yield put(setNotes(sorted.concat(invalid)))
    } catch (e) {
        notifyUser(e, 'error')
    }
}

export function* createNote(action: CreateNote) {
    try {
        const note = action.payload
        const id = uuidv4()
        const date = moment().format()
        const data = {
            ...note,
            date,
            uuid: id,
        }
        yield setDoc(doc(db, 'notes', id), data)
        yield put(getNotesAction(note.noteId))
    } catch (e) {
        notifyUser(e, 'error')
    }
}

export function* deleteNote(action: DeleteNote) {
    try {
        const { noteId, id } = action.payload
        yield deleteDoc(doc(db, 'notes', noteId))
        yield put(getNotesAction(id))
    } catch (e) {
        notifyUser(e, 'error')
    }
}

export default function* watcher() {
    yield takeEvery(GET_NOTES, getNotes)
    yield takeEvery(CREATE_NOTE, createNote)
    yield takeEvery(DELETE_NOTE, deleteNote)
}
