import { useAppSelector } from 'src/redux/app/app.hooks'
import { selectCurrentUserRoles } from 'src/redux/auth'
import { H5 } from 'src/ui-kit/headings/Headings'
import { checkIfRolesHavePermission } from 'src/components/app/utils/accessControl/accessControlUtils'
import { selectPermissionsForRoles } from 'src/redux/permissionsForRoles'

type Props = {
    children: any
    message?: boolean
    allow: string
}

const AccessControl = (props: Props) => {
    const { children, message, allow } = props
    const denyMessage = "You don't have access to view this page"
    const roles = useAppSelector(selectCurrentUserRoles)
    const permissionsForRoles = useAppSelector(selectPermissionsForRoles)

    if (roles) {
        let found = checkIfRolesHavePermission({ roles, allow, permissionsForRoles })

        if (found) {
            return children
        }

        if (message) {
            return <H5 data-testid='access-control-no-access'>{denyMessage}</H5>
        } else {
            return null
        }
    }
    return null
}

export default AccessControl
