import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import styled from 'styled-components'
import { firebase } from 'src/firebase'
import * as routes from 'src/constants/routes'
import * as colors from 'src/ui-kit/colors/colors'
import GoogleSignin from 'src/assets/images/google-signin.svg'
import { zIndex } from 'src/constants/zIndex'
import LoginBg from 'src/assets/images/login_bg.png'
import Logo from 'src/assets/images/ic_voi.svg'
import { Button } from 'src/ui-kit/button/Button'
import * as breakpoints from 'src/constants/breakpoints'

const googleAuthProvider = new GoogleAuthProvider()

interface Error {
    message: string
}

export const Login = () => {
    const [error, setError] = useState<Error>()

    const navigate = useNavigate()

    const handleClickLoginButton = () => {
        signInWithPopup(firebase.auth, googleAuthProvider)
            .then(() => {
                setError(undefined)
                navigate(routes.OVERVIEW)
            })
            .catch(error => {
                setError(error)
            })
    }

    return (
        <Form data-testid='login-page'>
            <Wrapper>
                <LogoImg src={Logo} />
                <Title>
                    <h1>
                        Welcome to <br />
                        Fleet Management
                    </h1>
                    <h2>Let&apos;s get started!</h2>
                </Title>
                <Button type={'primary'} onClick={handleClickLoginButton} color={'white'}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img width='14px' height='14px' src={GoogleSignin} alt='Google logo' />
                        <span
                            style={{
                                fontSize: '14px',
                                height: '14px',
                                lineHeight: '16px',
                                marginLeft: '8px',
                                color: colors.black,
                            }}
                        >
                            Continue with Google
                        </span>
                    </div>
                </Button>
                {error && <p data-testid='login-error-message'>{error.message}</p>}
            </Wrapper>
        </Form>
    )
}

const Form = styled.form`
    min-height: 100vh;
    height: auto;
    position: relative;
    background-color: black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: ${zIndex.priority};
    background-image: url(${`"${LoginBg}"`});
    background-size: cover;
    background-position: center;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 350px;
    margin-right: 300px;
    color: white;

    button {
        height: 41px;
    }

    @media (max-width: ${breakpoints.css.mobile}) {
        margin: 0 auto;
        padding: 10px;
    }
`

const Title = styled.div`
    color: ${colors.white};

    h1 {
        margin-top: 40px;
        font-weight: bold;
        font-size: 33px;
    }

    h2 {
        margin-top: 35px;
        font-size: 26px;
        font-weight: normal;
        margin-bottom: 45px;
    }
`

const LogoImg = styled.img`
    width: 105px;
`
