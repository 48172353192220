export const APP_ENV = process.env.REACT_APP_ENV as AppEnv

export const DEBUG = process.env.REACT_APP_DEBUG === 'true'

export const ERROR_REPORTING = process.env.REACT_APP_ERROR_REPORTING === 'true'

// The short SHA that the app was built with
export const BUILD_SHORT_SHA = process.env.REACT_APP_BUILD_SHORT_SHA ?? 'N/A'

export const HEATMAP_TRACKING_ENABLED = process.env.REACT_APP_HEATMAP_TRACKING_ENABLED === 'true'
// converting this to a number if its defined
export const HEATMAP_TRACKING_ID = process.env.REACT_APP_HEATMAP_TRACKING_ID
    ? +process.env.REACT_APP_HEATMAP_TRACKING_ID
    : undefined
export const HEATMAP_TRACKING_VERSION = process.env.REACT_APP_HEATMAP_TRACKING_VERSION
    ? +process.env.REACT_APP_HEATMAP_TRACKING_VERSION
    : undefined

export type AppEnv = 'dev' | 'stage' | 'production'

export const isAppEnv = (env: AppEnv) => {
    return env === APP_ENV
}
