import { call, put, type SagaReturnType, select, takeEvery } from 'redux-saga/effects'
import {
    ADD_NEW_AREA,
    SAVE_EDITING_AREA_POLYGON,
    SAVE_EDITING_AREA_PROPERTIES,
    DELETE_AREA,
    DELETE_AREAS,
    type AddNewArea,
    type SaveEditingAreaPolygon,
    type SaveEditingAreaProperties,
    type DeleteArea,
    type DeleteAreas,
} from 'src/redux/areas/areas.types'
import { type Area, type AreaType, type FeatureCollection } from 'src/api/fm/zones/zones.model'
import {
    addNewAreasSuccess,
    deleteAreaSuccess,
    selectFilteredAreas,
    selectSelectedAreaId,
    saveEditingAreaPolygonSuccess,
    saveEditingAreaPolygonFailure,
    saveEditingAreaPropertiesSuccess,
    deleteAreasSuccess,
} from 'src/redux/areas'
import { createNewAreas, editArea, deleteAreaById, deleteAreasByIds } from 'src/api'
import { notifyUser } from 'src/components/parts/notifications/notifications'

type CreateNewAreasRes = SagaReturnType<typeof createNewAreas>

export function* addArea(action: AddNewArea) {
    try {
        const { area } = action.payload
        const feature = area.features.find(f => f.properties['area_type'])
        if (!feature) {
            yield call(notifyUser, 'no areaType in feature', 'error')
            return
        }
        const res: CreateNewAreasRes = yield call(createNewAreas, area, feature.properties['area_type'])
        if (res instanceof Error) {
            throw res
        }
        yield put(addNewAreasSuccess(res.features))
        yield call(notifyUser, 'Successfully added area', 'success')
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type EditAreaRes = SagaReturnType<typeof editArea>

export function* saveEditAreaPolygon(action: SaveEditingAreaPolygon) {
    // TODO: When merging all areas into one map, remove this payload. Then we'll
    // have a guarantee that we only are editing a single area at a time
    const { areaType } = action.payload

    try {
        const selectedAreas: Record<AreaType, FeatureCollection | null> = yield select(selectFilteredAreas)
        const selectedAreaIds: Record<AreaType, string | null> = yield select(selectSelectedAreaId)
        const editedAreaId = selectedAreaIds[areaType]
        const editedFeature = selectedAreas[areaType]?.features.find((feature: Area) => feature.id === editedAreaId)
        if (!editedFeature || !editedAreaId) {
            throw new Error('Something went wrong. Please try again later.')
        }
        const updatedArea: FeatureCollection = {
            type: 'FeatureCollection',
            features: [editedFeature],
        }

        const res: EditAreaRes = yield call(editArea, updatedArea, editedAreaId, areaType)
        if (res instanceof Error) {
            throw res
        }
        const newArea = res.features[0]
        if (!newArea) {
            throw new Error('Failed to fetch new area. Please refresh the page.')
        }

        yield put(saveEditingAreaPolygonSuccess(areaType, newArea))
        yield call(notifyUser, 'Successfully updated area', 'success')
    } catch (e) {
        yield put(saveEditingAreaPolygonFailure(areaType))
        yield call(notifyUser, e, 'error')
    }
}

export function* updateArea(action: SaveEditingAreaProperties) {
    try {
        const { properties, id, geometry } = action.payload

        const area: FeatureCollection = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    id,
                    properties,
                    geometry,
                },
            ],
        }

        const areaType = properties['area_type']

        const res: EditAreaRes = yield call(editArea, area, id, areaType)
        if (res instanceof Error) {
            throw res
        }

        const newArea = res.features?.[0]
        if (!newArea) {
            throw new Error('Failed to fetch new area. Please refresh the page.')
        }

        yield put(saveEditingAreaPropertiesSuccess(newArea))
        yield call(notifyUser, 'Successfully updated area', 'success')
    } catch (e) {
        yield call(notifyUser, e)
    }
}

type DeleteAreaByIdRes = SagaReturnType<typeof deleteAreaById>

export function* deleteArea(action: DeleteArea) {
    const { areaType, areaId } = action.payload
    const deleteResult: DeleteAreaByIdRes = yield call(deleteAreaById, areaId, areaType)

    if (deleteResult instanceof Error) {
        yield call(notifyUser, deleteResult, 'error')
    } else {
        yield put(deleteAreaSuccess(areaType, areaId))
        yield call(notifyUser, 'Successfully deleted area', 'success')
    }
}

type DeleteAreasByIdsRes = SagaReturnType<typeof deleteAreasByIds>

export function* deleteAreas(action: DeleteAreas) {
    const { areaType, areaIds } = action.payload

    const res: DeleteAreasByIdsRes = yield call(deleteAreasByIds, areaIds, areaType)

    if (res instanceof Error) {
        yield call(notifyUser, res, 'error')
    } else {
        yield put(deleteAreasSuccess(areaType, areaIds))
        yield call(notifyUser, 'Successfully deleted areas', 'success')
    }
}

export default function* watcher() {
    yield takeEvery(ADD_NEW_AREA, addArea)
    yield takeEvery(SAVE_EDITING_AREA_POLYGON, saveEditAreaPolygon)
    yield takeEvery(SAVE_EDITING_AREA_PROPERTIES, updateArea)
    yield takeEvery(DELETE_AREA, deleteArea)
    yield takeEvery(DELETE_AREAS, deleteAreas)
}
