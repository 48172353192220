import { ins } from 'src/api/axios'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'
import {
    type Vehicle,
    type UpdateVehicleRequest,
    type VehicleModel,
    type GetVehicleModelsResponse,
    type GetVehicleResponse,
    type UpdateVehicleResponse,
    type GetVehiclesBulkResponse,
    type CreateVehicleModelResponse,
    type UpdateVehicleModelResponse,
} from 'src/api/fm/vehicles/vehicles.model'
import * as fileTransfer from 'src/utils/fileTransfer/fileTransferUtils'

const VEHICLES_PAGE_SIZE = 20000

export interface GetVehicleParams {
    zone: string
    battery_lt: number
    battery_gte: number
    availability_status?: string | null
    model_ids?: string | null
    organization_id?: string
}

export const getVehiclesInZone = async (filters: GetVehicleParams): Promise<Vehicle[] | Error> => {
    const link = '/v2/fm/vehicles'
    const headers = await getHeaders()

    const params = {
        ...filters,
        page_size: VEHICLES_PAGE_SIZE,
        battery_lt: filters.battery_lt === 100 ? null : filters.battery_lt + 1, // +1 to include vehicles have battery level in the same value of battery_lt
    }
    return ins
        .get<GetVehiclesBulkResponse>(link, { params, headers })
        .then(res => mapGetVehiclesBulkResponseToVehicles(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getVehiclesByLongIds = async (longs: string[]): Promise<Vehicle[] | Error> => {
    const params = {
        ids: longs.join(','),
        page_size: VEHICLES_PAGE_SIZE,
    }

    const link = '/v2/fm/vehicles'
    const headers = await getHeaders()
    return ins
        .get(link, { params, headers })
        .then(res => mapGetVehiclesBulkResponseToVehicles(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getVehiclesByShortIds = async (shorts: string[]): Promise<Vehicle[] | Error> => {
    const params = {
        shorts: shorts.join(','),
        page_size: VEHICLES_PAGE_SIZE,
    }

    const link = '/v2/fm/vehicles'
    const headers = await getHeaders()
    const res = await ins.get(link, { params, headers })
    return mapGetVehiclesBulkResponseToVehicles(res.data)
}

export const getVehiclesByVins = async (vins: string[]): Promise<Vehicle[] | Error> => {
    const params = {
        vins: vins.join(','),
        page_size: VEHICLES_PAGE_SIZE,
    }

    const link = '/v2/fm/vehicles'
    const headers = await getHeaders()
    return ins
        .get<GetVehiclesBulkResponse>(link, { params, headers })
        .then(res => mapGetVehiclesBulkResponseToVehicles(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/vehicle%20models/listModels API documentation}
 */
export const updateVehicle = async (vehicleId: string, data: UpdateVehicleRequest): Promise<Vehicle | Error> => {
    const link = encodeURI(`v2/fm/vehicles/${vehicleId}`)
    const headers = await getHeaders()

    return ins
        .put(link, data, { headers })
        .then(res => mapUpdateVehicleResponseToVehicle(res.data.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const updateVehicleZoneBulk = async (vehicle_ids: string[], zone: string): Promise<void | Error> => {
    const body = { vehicle_ids, data: { zone: parseInt(zone.toString()) } }
    const link = encodeURI('v1/fm/vehicles')
    const headers = await getHeaders()

    return ins
        .put(link, body, { headers })
        .then(() => undefined)
        .catch(e => getError(e))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/vehicles/getVehicle API documentation}
 */
export async function getVehicle(id: string): Promise<Vehicle | Error> {
    const link = `/v2/fm/vehicles/${id}`
    const headers = await getHeaders()

    return ins
        .get(link, { headers })
        .then(res => mapGetVehicleResponseToVehicle(res.data.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/vehicle%20models/listModels API documentation}
 */
export const getVehicleModels = async (): Promise<VehicleModel[] | Error> => {
    const url = `/v1/fm/vehicles/models`
    const headers = await getHeaders()

    return ins
        .get<GetVehicleModelsResponse>(url, { headers })
        .then(res => mapGetVehicleModels(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/vehicle%20models/createModel API documentation}
 */
export const createVehicleModel = async (vehicle: VehicleModel): Promise<VehicleModel | Error> => {
    const link = encodeURI('/v1/fm/vehicles/models')
    const headers = await getHeaders()
    const formData = new FormData()
    formData.append('metadata', JSON.stringify(vehicle))

    return ins
        .post<CreateVehicleModelResponse>(link, formData, {
            headers: { ...headers, 'Content-Type': 'multipart/form-data' },
        })
        .then(res => mapCreateVehicleModel(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/vehicle%20models/updateModel API documentation}
 */
export const updateVehicleModel = async (vehicle: VehicleModel): Promise<VehicleModel | Error> => {
    const link = encodeURI(`/v1/fm/vehicles/models/${vehicle.id}`)
    const headers = await getHeaders()
    const formData = fileTransfer.transformToFormData(vehicle)

    return ins
        .put<UpdateVehicleModelResponse>(link, formData, {
            headers: { ...headers, 'Content-Type': 'multipart/form-data' },
        })
        .then(res => mapUpdateVehicleModel(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/vehicle%20models/deleteModelPicture API documentation}
 */
export const deleteVehicleModelImage = async (id: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/vehicles/models/${id}/picture`)
    const headers = await getHeaders()
    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export async function bikeUnarmLock(id: string): Promise<undefined | Error> {
    const link = `/v1/fm/vehicles/disarm/${id}`
    const body = {}
    const headers = await getHeaders()

    return ins
        .patch(link, body, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetVehiclesBulkResponseToVehicles = (response: GetVehiclesBulkResponse): Vehicle[] => {
    return response.data.map(vehicle => {
        const { createdAt, locationTs, locationSource, ownerEntityId, registrationPlate, zoneId, ...rest } = vehicle

        return {
            ...rest,
            created_at: createdAt,
            location_ts: locationTs,
            location_source: locationSource,
            owner_entity_id: ownerEntityId,
            registration_plate: registrationPlate,
            zone_id: zoneId,
        }
    })
}

const mapGetVehicleResponseToVehicle = (response: GetVehicleResponse): Vehicle => {
    const { createdAt, locationTs, locationSource, ownerEntityId, registrationPlate, zoneId, ...rest } = response

    return {
        ...rest,
        created_at: createdAt,
        location_ts: locationTs,
        location_source: locationSource,
        owner_entity_id: ownerEntityId,
        registration_plate: registrationPlate,
        zone_id: zoneId,
    }
}

const mapUpdateVehicleResponseToVehicle = (response: UpdateVehicleResponse): Vehicle => {
    const { createdAt, locationTs, locationSource, ownerEntityId, registrationPlate, zoneId, ...rest } = response

    return {
        ...rest,
        created_at: createdAt,
        location_ts: locationTs,
        location_source: locationSource,
        owner_entity_id: ownerEntityId,
        registration_plate: registrationPlate,
        zone_id: zoneId,
    }
}

const mapGetVehicleModels = (response: GetVehicleModelsResponse): VehicleModel[] => {
    return response.data.map(
        ({ id, name, category, foldable, iot_type, range, weight, picture, battery, dimensions }) => ({
            id,
            name,
            iotType: iot_type,
            range,
            foldable,
            weight: Number(weight),
            category,
            picture,
            battery,
            dimensions,
        }),
    )
}

const mapCreateVehicleModel = (response: CreateVehicleModelResponse): VehicleModel => {
    const { id, name, category, foldable, iot_type, range, weight, picture, battery, dimensions } = response.data

    return {
        id,
        name,
        iotType: iot_type,
        range,
        foldable,
        weight: Number(weight),
        category,
        picture,
        battery,
        dimensions,
    }
}

const mapUpdateVehicleModel = (response: UpdateVehicleModelResponse): VehicleModel => {
    const { id, name, category, foldable, iot_type, range, weight, picture, battery, dimensions } = response.data

    return {
        id,
        name,
        iotType: iot_type,
        range,
        foldable,
        weight: Number(weight),
        category,
        picture,
        battery,
        dimensions,
    }
}
