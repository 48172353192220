import moment, { type Moment } from 'moment'

// Parses a unix timestamp into a moment object converted into the users current timezone
export function unixToMoment(unixTime: number) {
    return moment.unix(unixTime).local()
}

// Parses a UTC time into a moment object converted into the users current timezone
export function utcToMoment(utcTime: string) {
    return moment.utc(utcTime).local()
}

export function formatUnixDate(unixTime: number | undefined) {
    if (unixTime === undefined) {
        return
    }
    return unixToMoment(unixTime).format('YYYY-MM-DD HH:mm')
}

export function formatUtcDate(utcTime: string) {
    return utcToMoment(utcTime).format('YYYY-MM-DD HH:mm')
}

/**
 * Will return a relative time from now.
 * Example: 'in 4 years'
 * @param momentOrUnixTime number or moment object
 * @param isWithoutPrefix pass true for without prefix. '16 days' instead of 'in 16 days'
 * read more on at https://momentjs.com/docs/#/displaying/tonow/
 */
export const timefromNow = (momentOrUnixTime: number | Moment, isWithoutPrefix: boolean = false): string => {
    if (moment.isMoment(momentOrUnixTime)) {
        return momentOrUnixTime.fromNow(isWithoutPrefix)
    }
    return unixToMoment(momentOrUnixTime).fromNow(isWithoutPrefix)
}
