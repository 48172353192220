import { call, put, takeEvery } from 'redux-saga/effects'
import { setAuth, setAuthError, setRoles, setUser } from 'src/redux/auth/auth.actions'
import { LOGOUT_USER, LOGIN_USER, type LoginUser, type LogoutUser } from 'src/redux/auth/auth.types'
import { getCurrentRoles } from 'src/components/app/utils/accessControl/accessControlUtils'
import { auth, AuthTokenParseError, type TokensBundle } from 'src/api/auth'
import { auth as firebaseAuth } from 'src/firebase'
import { notifyUser } from 'src/components/parts/notifications/notifications'

export function* logoutUser(_: LogoutUser) {
    try {
        yield call(firebaseAuth.doSignOut)
        yield call(auth.clearTokens)
        yield put(setUser(null))
        yield put(setAuth(null))
        yield put(setRoles(null))
    } catch (e) {
        notifyUser(e, 'error')
    }
}

export function* loginUser(action: LoginUser) {
    try {
        const user = action.payload
        if (user) {
            const tokens: TokensBundle = yield call(auth.getTokens)
            if (getCurrentRoles(tokens.accessTokenParsed.role.global)) {
                yield put(setAuth(true))
                yield put(
                    setUser({ userId: tokens.accessTokenParsed.userId, email: user.email!, photoUrl: user.photoURL }),
                )
                yield put(setRoles(tokens.accessTokenParsed.role.global))
            } else {
                yield put(setAuthError('Missing FM role'))
            }
        }
    } catch (e) {
        if (e instanceof AuthTokenParseError) {
            yield put(setAuthError('Invalid ID token'))
        } else {
            yield put(setAuthError('Failed to validate ID token'))
        }
    }
}

export default function* watcher() {
    yield takeEvery(LOGOUT_USER, logoutUser)
    yield takeEvery(LOGIN_USER, loginUser)
}
