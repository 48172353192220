import { type TaskFormProps } from 'src/components/parts/active-tasks/createTaskForm/task.types'
import { type EditedTaskPayload } from 'src/components/parts/active-tasks/editTaskForm/EditTaskForm'
import { type CloseTaskStatus, type Task, type TaskType } from 'src/api/fm/tasks/tasks.model'
import {
    GET_ACTIVE_TASKS_FOR_VEHICLE,
    GET_ACTIVE_TASKS_ORG,
    SET_ACTIVE_TASKS,
    RESET_ACTIVE_TASKS,
    DELETE_TASK_FOR_VEHICLE,
    DELETE_TASK_FOR_ORGANIZATION,
    CLOSE_TASK_FOR_ORGANIZATION,
    CLOSE_TASK_FOR_VEHICLE,
    ADD_ACTIVE_TASK_TO_BULK,
    REMOVE_ACTIVE_TASK_FROM_BULK,
    ADD_ALL_ACTIVE_TASKS_TO_BULK,
    REMOVE_ALL_ACTIVE_TASKS_FROM_BULK,
    BULK_DROP_ACTIVE_TASKS_ORG,
    CREATE_TASK_FOR_VEHICLE,
    CREATE_TASK_FOR_ORGANIZATION,
    EDIT_TASK_VEHICLE,
    EDIT_TASK_ORGANIZATION,
    GET_TASK_TYPES,
    SET_TASK_TYPES,
    type CreateTaskForVehicle,
    type CreateTaskForOrganization,
    type GetActiveTasksForVehicle,
    type GetActiveTasksOrg,
    type SetActiveTasks,
    type ResetActiveTasks,
    type DeleteTaskForVehicle,
    type DeleteTaskForOrganization,
    type CloseTaskForVehicle,
    type CloseTaskForOrganization,
    type AddActiveTaskToBulk,
    type RemoveActiveTaskFromBulk,
    type RemoveAllActiveTasksFromBulk,
    type AddAllActiveTasksToBulk,
    type BulkDropActiveTasksOrg,
    type EditTaskVehicle,
    type EditTaskOrganization,
    type GetTaskTypes,
    type SetTaskTypes,
} from 'src/redux/task/task.types'

export const createTaskForVehicle = (task: TaskFormProps, vehicleId: string): CreateTaskForVehicle => {
    return {
        type: CREATE_TASK_FOR_VEHICLE,
        payload: {
            task,
            vehicleId,
        },
    }
}

export const createTaskForOrganization = (task: TaskFormProps, organizationId: string): CreateTaskForOrganization => {
    return {
        type: CREATE_TASK_FOR_ORGANIZATION,
        payload: {
            task,
            organizationId,
        },
    }
}

export const getActiveTasksForVehicleAction = (vehicleId: string): GetActiveTasksForVehicle => {
    return {
        type: GET_ACTIVE_TASKS_FOR_VEHICLE,
        payload: { vehicleId },
    }
}

export const getActiveTasksOrg = (id: string | undefined, email?: string): GetActiveTasksOrg => {
    return {
        type: GET_ACTIVE_TASKS_ORG,
        payload: { id, email },
    }
}

export const setActiveTasks = (tasks: Task[]): SetActiveTasks => {
    return {
        type: SET_ACTIVE_TASKS,
        payload: tasks,
    }
}

export const resetActiveTasks = (): ResetActiveTasks => ({
    type: RESET_ACTIVE_TASKS,
})

export const deleteTaskForVehicle = (task: Task): DeleteTaskForVehicle => {
    return {
        type: DELETE_TASK_FOR_VEHICLE,
        payload: task,
    }
}

export const deleteTaskForOrganization = (task: Task): DeleteTaskForOrganization => {
    return {
        type: DELETE_TASK_FOR_ORGANIZATION,
        payload: task,
    }
}

export interface CloseTaskVehicleData {
    taskId: string
    vehicleId: string
    status: CloseTaskStatus
    warehouseId?: string
}

export const closeTaskForVehicle = (data: CloseTaskVehicleData): CloseTaskForVehicle => {
    return {
        type: CLOSE_TASK_FOR_VEHICLE,
        payload: data,
    }
}

export interface CloseTaskOrganizationData {
    taskId: string
    status: CloseTaskStatus
    accountId: string
    warehouseId?: string
}

export const closeTaskForOrganization = (data: CloseTaskOrganizationData): CloseTaskForOrganization => {
    return {
        type: CLOSE_TASK_FOR_ORGANIZATION,
        payload: data,
    }
}

export const addActiveTaskToBulk = (task: Task): AddActiveTaskToBulk => {
    return {
        type: ADD_ACTIVE_TASK_TO_BULK,
        payload: task,
    }
}

export const removeActiveTaskFromBulk = (task: Task): RemoveActiveTaskFromBulk => {
    return {
        type: REMOVE_ACTIVE_TASK_FROM_BULK,
        payload: task,
    }
}

export const addAllActiveTasksToBulk = (tasks: Task[]): AddAllActiveTasksToBulk => {
    return {
        type: ADD_ALL_ACTIVE_TASKS_TO_BULK,
        payload: tasks,
    }
}

export const removeAllActiveTasksFromBulk = (): RemoveAllActiveTasksFromBulk => {
    return {
        type: REMOVE_ALL_ACTIVE_TASKS_FROM_BULK,
    }
}

export interface BulkDropActiveTasksOrganizationData {
    tasks: Task[]
    status: CloseTaskStatus
    accountId: string
    warehouseId?: string
}

export const bulkDropActiveTasksOrg = (tasks: BulkDropActiveTasksOrganizationData): BulkDropActiveTasksOrg => {
    return {
        type: BULK_DROP_ACTIVE_TASKS_ORG,
        payload: tasks,
    }
}

export const editTaskVehicle = (task: EditedTaskPayload, vehicleId: string): EditTaskVehicle => {
    return {
        type: EDIT_TASK_VEHICLE,
        payload: {
            task,
            vehicleId,
        },
    }
}

export const editTaskOrganization = (task: EditedTaskPayload, organizationId: string): EditTaskOrganization => {
    return {
        type: EDIT_TASK_ORGANIZATION,
        payload: {
            task,
            organizationId,
        },
    }
}

export const getTaskTypes = (): GetTaskTypes => {
    return {
        type: GET_TASK_TYPES,
    }
}

export const setTaskTypes = (taskTypes: TaskType[]): SetTaskTypes => {
    return {
        type: SET_TASK_TYPES,
        payload: taskTypes,
    }
}
