export const OVERVIEW = '/'
export const RIDERS = '/riders'
export const ACCIDENT_REPORTS = '/accident-reports'
export const HUNTERS = '/hunters'
export const ORG = '/organization'
export const CREATE_ORG = '/create-organization'
export const VEHICLES = '/vehicles'
export const PARKING_PHOTO_LABELLING = '/parking-photo-labelling'
export const SAFETY_CONTROL_SYSTEM = '/safety-control-system'
export const VMAP = '/vehicle-map'
export const ZONES = '/zones'
export const MAAS = '/maas'
export const LOGIN = '/login'
export const CAMPAIGNS = '/campaigns'
export const PERMISSIONS = '/permissions'
export const LEGAL = '/legal'
export const REPAIR_MANAGEMENT = '/repairs'
export const ADMINISTRATION = '/administration'
export const VEHICLE_REPORTS = '/vehicle-reports'
export const RIDE_INSPECTOR = '/ride-inspector'
export const ROUTING_WEB = '/routing-web'
export const PARKING_WEB = '/parking-web'
export const WAREHOUSES = '/warehouses'
export const CONFIGHUB = '/confighub'
export const CITYREGS = '/cityregs'
export const DEEP_INTEGRATIONS = '/deep-integrations'
export const MAINTENANCE_NEEDS = '/maintenance-needs'
export const WALLET = '/wallet'
export const PRICING_PORTAL = '/portal/pricing'
