import { type AreaType } from 'src/api/fm/zones/zones.model'

// List of area types that will be displayed as layers on the vehicle map.
// Take note that the order will determine how the layers will overlap.
export const MAP_AREA_TYPES: AreaType[] = [
    'operations',
    'no-parking',
    'bounty',
    'slow-zone',
    'no-riding',
    'soft-mpz',
    'free-floating',
]

export const DEFAULT_MAP_SETTINGS = {
    toggledAreaTypes: MAP_AREA_TYPES,
}

export interface MapSettings {
    toggledAreaTypes: AreaType[]
}
