import { type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { BaseErrorBoundary } from 'src/components/parts/errorBoundaries/components/BaseErrorBoundary'
import { ErrorContainer, ErrorMessage } from 'src/components/parts/errorBoundaries/components/ErrorViewParts'

const GlobalErrorView = () => (
    <PageContainer>
        <ErrorContainer $padding='40px'>
            <ErrorMessage $fontSize='22px'>
                An error occurred. Please refresh the page. If the problem persists, contact the support team.
            </ErrorMessage>
        </ErrorContainer>
    </PageContainer>
)

export const GlobalErrorBoundary = ({ children }: PropsWithChildren<{}>) => (
    <BaseErrorBoundary fallback={GlobalErrorView}>{children}</BaseErrorBoundary>
)

const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
`
