import { Component, type PropsWithChildren } from 'react'

/**
 * This is a custom error boundary to catch failures of fetching dynamically imported modules.
 * These failures intermittently occur right after a deploy due to module chunks being renamed (more specifically their hash suffix).
 *
 * Please see https://github.com/vitejs/vite/issues/11804 for more information
 */
export class ImportErrorBoundary extends Component<PropsWithChildren<{}>> {
    componentDidCatch(error: Error) {
        if (error?.message?.includes('Failed to fetch dynamically imported module')) {
            console.error(
                '%cFailed to load module chunk. Therefore forcing reload of the page...',
                'padding:5px 10px; background: #ffe8ce;',
                error,
            )
            window.location.reload()
        } else {
            // Rethrow error if it's of some other nature so that it can be caught by the global error boundary
            throw error
        }
    }

    render() {
        return this.props.children
    }
}
