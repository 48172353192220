import {
    SET_ZONE_OPTIONS,
    DELETE_ZONE_INTENT,
    EDIT_COUNTRY_INTENT,
    RESET_CURRENT_ZONE,
    SET_ALL_COUNTRIES,
    ADD_COUNTRY_SUCCESS,
    EDIT_COUNTRY_SUCCESS,
    SET_ALL_ZONES,
    SET_CURRENT_ZONE,
    SET_CURRENT_ZONE_ERROR,
    SET_VEHICLE_PRICING,
    SET_ZONES,
    SET_ZONE_FEATURES,
    SET_VOI_PASS_GROUPS,
    SET_ZONE_PARKING_ASSISTANT_SETTINGS,
    UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS,
    GET_PARKING_PHOTO_SETTINGS_SUCCESS,
    type ZoneState,
    SET_VEHICLE_POLICY,
    SET_ZONE_THRESHOLDS,
    SET_VOI_PASS_DISCOUNT,
} from 'src/redux/zone/zone.types'
import { type ZoneAction } from 'src/redux/zone/zone.actions'

export const INITIAL_STATE: ZoneState = {
    zones: null,
    editCountry: null,
    zoneOptions: [],
    deleteZone: null,
    allZones: [],
    allCountries: [],
    currentZone: {
        data: null,
        error: null,
    },
    zoneFeatures: null,
    vehiclePricing: null,
    voiPassGroups: null,
    voiPassDiscount: null,
    zoneParkingAssistantSettings: null,
    parkingPhotoSettings: [],
    vehiclePolicy: null,
}

export function zoneReducer(state: ZoneState = INITIAL_STATE, action: ZoneAction): ZoneState {
    switch (action.type) {
        case EDIT_COUNTRY_INTENT:
            return { ...state, editCountry: action.payload }

        case SET_ZONE_OPTIONS:
            return { ...state, zoneOptions: action.payload }

        case DELETE_ZONE_INTENT:
            return { ...state, deleteZone: action.payload }

        case SET_ZONES:
            return { ...state, zones: action.payload }

        case SET_CURRENT_ZONE:
            return {
                ...state,
                currentZone: {
                    data: action.payload,
                    error: null,
                },
            }

        case SET_CURRENT_ZONE_ERROR:
            return {
                ...state,
                currentZone: {
                    data: null,
                    error: action.payload.error,
                },
            }

        case SET_ZONE_THRESHOLDS:
            return {
                ...state,
                currentZone: {
                    data: { ...state.currentZone.data!, thresholds: action.payload },
                    error: null,
                },
            }

        case RESET_CURRENT_ZONE: {
            return {
                ...state,
                currentZone: {
                    data: null,
                    error: null,
                },
                zoneFeatures: null,
                vehiclePricing: null,
                zoneParkingAssistantSettings: null,
                voiPassGroups: null,
                voiPassDiscount: null,
                parkingPhotoSettings: [],
            }
        }

        case SET_ALL_COUNTRIES:
            return { ...state, allCountries: action.payload }

        case ADD_COUNTRY_SUCCESS: {
            const { newCountry } = action.payload
            const oldCountries = state.allCountries
            const allCountries = [...oldCountries, newCountry]

            return { ...state, allCountries }
        }

        case EDIT_COUNTRY_SUCCESS: {
            const { editedCountry } = action.payload
            const oldCountries = state.allCountries
            const allCountries = oldCountries.map(country =>
                country.country === editedCountry.country ? editedCountry : country,
            )

            return { ...state, allCountries }
        }

        case SET_ALL_ZONES:
            return { ...state, allZones: action.payload }

        case SET_ZONE_FEATURES:
            return { ...state, zoneFeatures: action.payload }

        case SET_VEHICLE_POLICY:
            return { ...state, vehiclePolicy: action.payload }

        case SET_VEHICLE_PRICING:
            return { ...state, vehiclePricing: action.payload }

        case SET_VOI_PASS_GROUPS:
            return { ...state, voiPassGroups: action.payload }

        case SET_VOI_PASS_DISCOUNT:
            return { ...state, voiPassDiscount: action.payload }

        case SET_ZONE_PARKING_ASSISTANT_SETTINGS:
            return {
                ...state,
                zoneParkingAssistantSettings: action.payload,
            }

        case UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS:
            return {
                ...state,
                zoneParkingAssistantSettings: action.payload,
            }

        case GET_PARKING_PHOTO_SETTINGS_SUCCESS: {
            const { parkingPhotoSettings } = action.payload
            return { ...state, parkingPhotoSettings }
        }

        default:
            return state
    }
}
