import { ins } from 'src/api/axios'
import { type GetNextPhotoToLabelResponse } from 'src/api/fm/photoLabels/photoLabels.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'
import { type PhotoToLabel } from 'src/redux/photoLabels/photoLabels.types'

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/photo-labels/getNextPhotoToLabel API documentation}
 */
export const getNextPhotoToLabel = async (): Promise<PhotoToLabel | Error> => {
    const link = encodeURI(`/v1/fm/photo-labels/photos/next`)
    const headers = await getHeaders()

    return ins
        .get<GetNextPhotoToLabelResponse>(link, { headers })
        .then(res => mapToPhotoToLabel(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapToPhotoToLabel = (data: GetNextPhotoToLabelResponse): PhotoToLabel | Error => {
    if (data.data !== null) {
        const photoToLabel: PhotoToLabel = {
            kind: data.data.kind,
            photoRef: data.data.photo_ref,
            photoUrl: data.data.photo_url,
            rideId: data.data.ride_id,
            userId: data.data.user_id,
            zoneId: data.data.zone_id,
            createdAt: data.data.created_at,
            takenAt: data.data.taken_at,
        }

        return photoToLabel
    }

    return new Error('No photo to review')
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/photo-labels/labelPhoto API documentation}
 */
export const labelPhoto = async (photoRef: string, labels: string[]): Promise<string | Error> => {
    const link = encodeURI(`/v1/fm/photo-labels/photos`)
    const payload = { photo_ref: photoRef, labels }
    const headers = await getHeaders()

    return ins
        .put(link, payload, { headers })
        .then(res => res.data.Data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
