import { createSelector } from 'reselect'
import { type AppState } from 'src/redux/app/app.store'
import { type EditableArea } from 'src/redux/areas/areas.types'
import { type AreaType, type FeatureCollection } from 'src/api/fm/zones/zones.model'

const selectAreas = (state: AppState) => state.areas.areas
const selectAreaFilters = (state: AppState) => ({
    operations: state.areas.areas.operations?.filter || '',
    bounty: state.areas.areas.bounty?.filter || '',
    'no-parking': state.areas.areas['no-parking']?.filter || '',
    'slow-zone': state.areas.areas['slow-zone']?.filter || '',
    'no-riding': state.areas.areas['no-riding']?.filter || '',
    'fleet-capacity': state.areas.areas['fleet-capacity']?.filter || '',
    'soft-mpz': state.areas.areas['soft-mpz']?.filter || '',
    'free-floating': state.areas.areas['free-floating']?.filter || '',
})
// Cover in test case
// If areas not loaded: null
// If areas loaded and no edit draft: originalFeatureCollection
// If areas loaded and current edit draft: draftFeatureCollection

// Change name to selectDisplayedFeatureCollection ? :)
const selectDisplayedAreas = (state: AppState) => ({
    operations: getDisplayedAreasForType(state.areas.areas.operations),
    bounty: getDisplayedAreasForType(state.areas.areas.bounty),
    'no-parking': getDisplayedAreasForType(state.areas.areas['no-parking']),
    'slow-zone': getDisplayedAreasForType(state.areas.areas['slow-zone']),
    'no-riding': getDisplayedAreasForType(state.areas.areas['no-riding']),
    'fleet-capacity': getDisplayedAreasForType(state.areas.areas['fleet-capacity']),
    'soft-mpz': getDisplayedAreasForType(state.areas.areas['soft-mpz']),
    'free-floating': getDisplayedAreasForType(state.areas.areas['free-floating']),
})

export const selectExistingAreaTypes = createSelector([selectAreas], areas =>
    Object.entries(areas)
        .filter(([_, value]) => Boolean(value))
        .map(([key]) => key as AreaType),
)

// QUESTION: Think we need to pass all areas down to GeoMap and filter away there.
// Since GeoMap will update a ENTIRE featureCollection on Edit. We don't want to
// remove areas if editing with filter set...
// or will it in fact work cuz in BE request we only PUT one area with the area id? need to check
// also add e2e tests for this
export const selectFilteredAreas = createSelector(
    [selectDisplayedAreas, selectAreaFilters],
    (displayedAreas, filters) => ({
        operations: filterAreas(displayedAreas.operations, filters.operations),
        bounty: filterAreas(displayedAreas.bounty, filters.bounty),
        'no-parking': filterAreas(displayedAreas['no-parking'], filters['no-parking']),
        'slow-zone': filterAreas(displayedAreas['slow-zone'], filters['slow-zone']),
        'no-riding': filterAreas(displayedAreas['no-riding'], filters['no-riding']),
        'fleet-capacity': filterAreas(displayedAreas['fleet-capacity'], filters['fleet-capacity']),
        'soft-mpz': filterAreas(displayedAreas['soft-mpz'], filters['soft-mpz']),
        'free-floating': filterAreas(displayedAreas['free-floating'], filters['free-floating']),
    }),
)

export const selectExpandedAreaIds = createSelector([selectAreas], areas => ({
    operations: getExpandedAreaIds(areas.operations),
    bounty: getExpandedAreaIds(areas.bounty),
    'no-parking': getExpandedAreaIds(areas['no-parking']),
    'slow-zone': getExpandedAreaIds(areas['slow-zone']),
    'no-riding': getExpandedAreaIds(areas['no-riding']),
    'fleet-capacity': getExpandedAreaIds(areas['fleet-capacity']),
    'soft-mpz': getExpandedAreaIds(areas['soft-mpz']),
    'free-floating': getExpandedAreaIds(areas['free-floating']),
}))

export const selectSelectedFeatureIndexes = createSelector([selectAreas], areas => ({
    operations: areas.operations?.selectedFeatureIndexes || [],
    bounty: areas.bounty?.selectedFeatureIndexes || [],
    'no-parking': areas['no-parking']?.selectedFeatureIndexes || [],
    'slow-zone': areas['slow-zone']?.selectedFeatureIndexes || [],
    'no-riding': areas['no-riding']?.selectedFeatureIndexes || [],
    'fleet-capacity': areas['fleet-capacity']?.selectedFeatureIndexes || [],
    'soft-mpz': areas['soft-mpz']?.selectedFeatureIndexes || [],
    'free-floating': areas['free-floating']?.selectedFeatureIndexes || [],
}))

export const selectSelectedAreaId = createSelector([selectAreas], areas => ({
    operations: areas.operations?.selectedFeatureId || null,
    bounty: areas.bounty?.selectedFeatureId || null,
    'no-parking': areas['no-parking']?.selectedFeatureId || null,
    'slow-zone': areas['slow-zone']?.selectedFeatureId || null,
    'no-riding': areas['no-riding']?.selectedFeatureId || null,
    'fleet-capacity': areas['fleet-capacity']?.selectedFeatureId || null,
    'soft-mpz': areas['soft-mpz']?.selectedFeatureId || null,
    'free-floating': areas['free-floating']?.selectedFeatureId || null,
}))

export const selectIsEditing = createSelector([selectAreas], areas => ({
    operations: areas.operations?.mapMode === 'edit',
    bounty: areas.bounty?.mapMode === 'edit',
    'no-parking': areas['no-parking']?.mapMode === 'edit',
    'slow-zone': areas['slow-zone']?.mapMode === 'edit',
    'no-riding': areas['no-riding']?.mapMode === 'edit',
    'fleet-capacity': areas['fleet-capacity']?.mapMode === 'edit',
    'soft-mpz': areas['soft-mpz']?.mapMode === 'edit',
    'free-floating': areas['free-floating']?.mapMode === 'edit',
}))

export const selectIsEditingAnyAreaType = createSelector([selectIsEditing], isEditing =>
    Object.values(isEditing).some(Boolean),
)

export const selectIsChanged = createSelector([selectAreas], areas => ({
    operations: areas.operations?.isChanged || false,
    bounty: areas.bounty?.isChanged || false,
    'no-parking': areas['no-parking']?.isChanged || false,
    'slow-zone': areas['slow-zone']?.isChanged || false,
    'no-riding': areas['no-riding']?.isChanged || false,
    'fleet-capacity': areas['fleet-capacity']?.isChanged || false,
    'soft-mpz': areas['soft-mpz']?.isChanged || false,
    'free-floating': areas['free-floating']?.isChanged || false,
}))

export const selectMapMode = createSelector([selectAreas], areas => ({
    operations: areas.operations?.mapMode || 'view',
    bounty: areas.bounty?.mapMode || 'view',
    'no-parking': areas['no-parking']?.mapMode || 'view',
    'slow-zone': areas['slow-zone']?.mapMode || 'view',
    'no-riding': areas['no-riding']?.mapMode || 'view',
    'fleet-capacity': areas['fleet-capacity']?.mapMode || 'view',
    'soft-mpz': areas['soft-mpz']?.mapMode || 'view',
    'free-floating': areas['free-floating']?.mapMode || 'view',
}))

const filterAreas = (area: FeatureCollection | null, filter: string): FeatureCollection | null => {
    if (!area) {
        return null
    }

    const filteredFeatures = area.features.filter(feature =>
        feature.properties.name.toLowerCase().includes(filter.toLowerCase()),
    )
    return {
        ...area,
        features: filteredFeatures,
    }
}

const getExpandedAreaIds = (area: EditableArea | null): Record<string, string> => {
    if (!area) {
        return {}
    }

    return area.expandedAreaIds
}

const getDisplayedAreasForType = (area: EditableArea | null): FeatureCollection | null => {
    if (!area) {
        return null
    }

    return area.draftFeatureCollection ? area.draftFeatureCollection : area.originalFeatureCollection
}
