import { ins } from 'src/api/axios'
import {
    type GetVehicleModelSparePartsResponse,
    type SparePart,
} from 'src/api/fm/warehouseInventory/warehouseInventory.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/warehouse%20inventory/getVehicleModelSpareParts API documentation}
 */
export const getVehicleModelSpareParts = async (vehicleModelId: string): Promise<SparePart[] | Error> => {
    const link = encodeURI(`/v1/fm/warehouse-inventory/vehicle-models/${vehicleModelId}/spare-parts`)
    const headers = await getHeaders()
    return ins
        .get<GetVehicleModelSparePartsResponse>(link, { headers })
        .then(res => mapGetVehicleModelSpareParts(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetVehicleModelSpareParts = (response: GetVehicleModelSparePartsResponse): SparePart[] => {
    return response.data.map(s => ({
        id: s.id,
        modelId: s.modelId,
        manufacturerId: s.manufacturerId,
        name: s.name,
        image: s.images[0] && {
            id: s.images[0].id,
            url: s.images[0].url,
        },
    }))
}
