import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import {
    type AccidentReport,
    type AccidentReportForm,
    type GetAccidentReportResponse,
    type GetAccidentReportItem,
    type GetAccidentReportsResponse,
    type AccidentReports,
    type AccidentReason,
    type GetAccidentReasonsResponse,
    type AccidentReasonResponse,
    type DeleteAccidentReasonResponse,
} from 'src/api/fm/accidents/accidents.model'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/listReport API documentation}
 */
export const getAccidentReports = async (
    limit: number,
    offset: number,
    query: string,
): Promise<AccidentReports | Error> => {
    const link = encodeURI(`/v2/fm/accidents/reports?limit=${limit}&offset=${offset}&query=${query}`)
    const headers = await getHeaders()
    return ins
        .get<GetAccidentReportsResponse>(link, { headers })
        .then(res => mapGetAccidentReports(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/getReport API documentation}
 */
export const getAccidentReportById = async (reportId: string): Promise<AccidentReport | Error> => {
    const link = encodeURI(`/v1/fm/accidents/reports/${reportId}`)
    const headers = await getHeaders()
    return ins
        .get<GetAccidentReportResponse>(link, { headers })
        .then(res => mapGetAccidentReportById(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/getAccidentReasons API documentation}
 */
export const getAccidentReasons = async (): Promise<GetAccidentReasonsResponse | Error> => {
    const link = encodeURI(`/v2/fm/accidents/reasons`)
    const headers = await getHeaders()
    return ins
        .get<GetAccidentReasonsResponse>(link, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/createAccidentReason API documentation}
 */
export const createAccidentReason = async (reason: AccidentReason): Promise<AccidentReasonResponse | Error> => {
    const link = encodeURI('/v2/fm/accidents/reasons')
    const headers = await getHeaders()
    return ins
        .post<AccidentReasonResponse>(link, { reason }, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/updateAccidentReason API documentation}
 */
export const editAccidentReason = async (
    reasonId: string,
    reason: AccidentReason,
): Promise<AccidentReasonResponse | Error> => {
    const link = encodeURI(`/v2/fm/accidents/reasons/${reasonId}`)
    const headers = await getHeaders()
    return ins
        .put<AccidentReasonResponse>(link, { reason }, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/deleteAccidentReason API documentation}
 */
export const deleteAccidentReason = async (reasonId: string): Promise<DeleteAccidentReasonResponse | Error> => {
    const link = encodeURI(`/v2/fm/accidents/reasons/${reasonId}`)
    const headers = await getHeaders()
    return ins
        .delete<DeleteAccidentReasonResponse>(link, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/createReport API documentation}
 */
export const createAccidentReport = async (reportForm: AccidentReportForm): Promise<string | Error> => {
    const link = encodeURI('/v1/fm/accidents/reports')
    const headers = await getHeaders()
    return ins
        .post<GetAccidentReportResponse>(link, { form: reportForm }, { headers })
        .then(res => res.data.data.id)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/updateReport API documentation}
 */
export const editAccidentReport = async (reportId: string, reportForm: AccidentReportForm): Promise<string | Error> => {
    const link = encodeURI(`/v1/fm/accidents/reports/${reportId}`)
    const headers = await getHeaders()
    return ins
        .put<GetAccidentReportResponse>(link, { form: reportForm }, { headers })
        .then(res => res.data.data.id)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/accidents/deleteReport API documentation}
 */
export const deleteAccidentReport = async (reportId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/accidents/reports/${reportId}`)
    const headers = await getHeaders()
    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapAccidentReport = (report: GetAccidentReportItem): AccidentReport => ({
    id: report.id,
    form: report.form,
    detection_data: report.detection_data,
    ride_id: report.ride_id,
    created_by_source: report.created_by_source,
    createdAt: report.createdAt,
    createdBy: report.createdBy,
    updatedAt: report.updatedAt,
    updatedBy: report.updatedBy,
})

const mapGetAccidentReportById = (response: GetAccidentReportResponse): AccidentReport =>
    mapAccidentReport(response.data)
const mapGetAccidentReports = (response: GetAccidentReportsResponse): AccidentReports => ({
    data: response.data.map(mapAccidentReport),
    totalCount: response.totalCount,
})
