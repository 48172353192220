export const nanToEmptyString = (value: number) => {
    if (Number.isNaN(value)) {
        return ''
    }

    return value
}

export const priceToCents = (price: string) => Math.round(Number(price) * 100)
export const numberPriceToCents = (price: number) => Math.round(price * 100)

export const centsToPriceString = (cents: number) => String(cents / 100)
export const centsToPriceNumber = (cents: number) => cents / 100
