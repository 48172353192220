import { type LicensePlate, type LicensePlateDeactivationError } from 'src/models/licensePlate'
import { ins } from 'src/api/axios'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'
import {
    type GetLicensePlatesBulkResponse,
    type DeleteActiveLicensePlatesBulkResponse,
} from 'src/api/fm/leap/leap.model'

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/leap/getVehicleLicenseBulk API documentation}
 */
export const getLicensePlatesBulk = async (vehicleIds: string[]): Promise<LicensePlate[] | Error> => {
    const url = `/v1/fm/leap/licenses`
    const headers = await getHeaders()
    const params = { vehicle_ids: vehicleIds.join(',') }

    return ins
        .get<GetLicensePlatesBulkResponse>(url, { headers, params })
        .then(res => mapGetLicensePlatesBulk(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/leap/deactivateVehicleLicenseBulk API documentation}
 */
export const deleteActiveLicensePlatesBulk = async (
    vehicleIds: string[],
): Promise<LicensePlateDeactivationError[] | Error> => {
    const url = '/v1/fm/leap/licenses/bulk'
    const headers = await getHeaders()
    const data = { vehicleIDs: vehicleIds }

    return ins
        .delete<DeleteActiveLicensePlatesBulkResponse>(url, { headers, data })
        .then(res => mapDeleteActiveLicensePlatesBulk(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetLicensePlatesBulk = (response: GetLicensePlatesBulkResponse): LicensePlate[] => {
    return response.licenses.map(l => ({
        vehicleId: l.vehicleID,
        number: l.licensePlate,
    }))
}

const mapDeleteActiveLicensePlatesBulk = (
    response: DeleteActiveLicensePlatesBulkResponse,
): LicensePlateDeactivationError[] => {
    return response.errors.map(e => ({
        vehicleId: e.vehicleID,
        reason: e.reason,
    }))
}
