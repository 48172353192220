import {
    EXPAND_ZONE,
    SET_NAV_EXPANDED,
    TOGGLE_MOBILE_NAV,
    EXPAND_REPAIRS,
    EXPAND_VEHICLES,
    EXPAND_RIDERS,
} from 'src/redux/nav/nav.types'

export const expandZone = (condition: boolean) => {
    return {
        type: EXPAND_ZONE,
        payload: condition,
    }
}

export const setNavExpanded = (condition: boolean) => {
    return {
        type: SET_NAV_EXPANDED,
        payload: condition,
    }
}

export const toggleMobileNav = (condition: boolean) => {
    return {
        type: TOGGLE_MOBILE_NAV,
        payload: condition,
    }
}

export const expandRepairs = (condition: boolean) => ({
    type: EXPAND_REPAIRS,
    payload: condition,
})

export const expandRiders = (condition: boolean) => ({
    type: EXPAND_RIDERS,
    payload: condition,
})

export const expandVehicles = (condition: boolean) => ({
    type: EXPAND_VEHICLES,
    payload: condition,
})
