import { call, takeEvery, type SagaReturnType, put } from 'redux-saga/effects'
import { getViolations } from 'src/api/confighub/violations/violations'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { setViolationsAction } from 'src/redux/violations/violations.actions'
import { GET_VIOLATIONS } from 'src/redux/violations/violations.types'

type GetViolationsRes = SagaReturnType<typeof getViolations>

export function* getViolationsSaga() {
    try {
        const res: GetViolationsRes = yield call(getViolations)
        if (res instanceof Error) {
            throw res
        }
        yield put(setViolationsAction(res))
    } catch (error) {
        yield call(notifyUser, error, 'error')
    }
}

export default function* watcher() {
    yield takeEvery(GET_VIOLATIONS, getViolationsSaga)
}
