export function chunk<T>(arr: Array<T>, len: number) {
    let chunks: Array<Array<T>> = []
    let i = 0

    while (i < arr.length) {
        chunks.push(arr.slice(i, (i += len)))
    }

    return chunks
}
