import { isObject, mapValues } from 'lodash-es'
import {
    type ChildStatusKey,
    type VmapFilters,
    type VmapZoneFilter,
    type VmapVehicleStatusFilter,
    type VmapVehicleStatusFilterKey,
    INITIAL_STATUS_FILTER,
    type VmapVehicleStatusFilterWithChild,
} from 'src/components/vmap/filters/types'
import {
    ADD_ZONE_FILTER_VMAP,
    REMOVE_STATUS_FILTER_VMAP,
    ADD_BATTERY_FILTER_VMAP,
    REMOVE_ZONE_FILTER_VMAP,
    SET_INITIAL_VMAP_FILTERS,
    SET_CURRENT_ZONE_VMAP,
    TOGGLE_VMAP_MODEL_FILTER,
    TOGGLE_VMAP_ALL_MODEL_FILTERS,
    SET_VEHICLES_VMAP,
    TOGGLE_MAP_LOADING_VMAP,
    TOGGLE_VMAP_ALL_STATES,
    TOGGLE_VMAP_STATE_PARENT,
    TOGGLE_VMAP_STATE_CHILD,
    RESET_VMAP_STATES,
    SET_MAP_INTERVAL,
    SET_ALL_VMAP_MODELS,
    RESET_VMAP_MODEL_FILTERS,
    TOGGLE_TASK_PRIORITY,
    TOGGLE_TASK_PRIORITY_FILTER,
    type VmapState,
    type VmapActions,
    SET_ORGANIZATION_VMAP_FILTER,
} from 'src/redux/vmap/vmap.types'
import { isAllStatesActive, isAllSubOptionsActive } from 'src/components/app/utils/vmap/vmapUtils'
import { type ExecutionPriority, EXECUTION_PRIORITY_OPTIONS } from 'src/api/fm/tasks/tasks.model'

export const INITIAL_STATE: VmapState = {
    filters: {
        city: null,
        status: INITIAL_STATUS_FILTER,
        battery: {
            battery_gte: 0,
            battery_lt: 100,
        },
        models: [],
        priorities: {
            isFilterActive: false,
            toggledPriorities: EXECUTION_PRIORITY_OPTIONS,
        },
        organization: undefined,
    },
    vehicles: [],
    allModels: [],
    mapInterval: '5000',
    mapLoading: false,
    currentZone: null,
}

export function vmapReducer(state: VmapState = INITIAL_STATE, action: VmapActions): VmapState {
    switch (action.type) {
        case SET_INITIAL_VMAP_FILTERS:
            return { ...state, filters: { ...state.filters, ...action.payload } }

        case ADD_ZONE_FILTER_VMAP:
            return { ...state, filters: addZoneFilter(state.filters, action.payload) }

        case REMOVE_ZONE_FILTER_VMAP:
            return { ...state, filters: removeZoneFilter(state.filters) }

        case REMOVE_STATUS_FILTER_VMAP:
            return { ...state, filters: { ...state.filters, status: INITIAL_STATE.filters.status } }

        case ADD_BATTERY_FILTER_VMAP:
            return { ...state, filters: { ...state.filters, battery: action.payload } }

        case SET_VEHICLES_VMAP:
            return { ...state, vehicles: action.payload }

        case TOGGLE_MAP_LOADING_VMAP:
            return { ...state, mapLoading: action.payload }

        case SET_MAP_INTERVAL:
            return { ...state, mapInterval: action.payload }

        case SET_CURRENT_ZONE_VMAP:
            return { ...state, currentZone: action.payload }

        case TOGGLE_VMAP_ALL_STATES:
            return { ...state, filters: { ...state.filters, status: toggleAllStateFilters(state.filters.status) } }

        case TOGGLE_VMAP_STATE_PARENT:
            return {
                ...state,
                filters: { ...state.filters, status: toggleStateParent(action.payload, state.filters.status) },
            }
        case RESET_VMAP_STATES:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    status: INITIAL_STATE.filters.status,
                },
            }

        case TOGGLE_VMAP_STATE_CHILD:
            return {
                ...state,
                filters: { ...state.filters, status: toggleStateChild(action.payload, state.filters.status) },
            }

        case TOGGLE_VMAP_MODEL_FILTER: {
            const modelId = action.payload
            const prevModels = state.filters.models
            const isModelActive = prevModels.includes(modelId)
            const models = isModelActive ? prevModels.filter(m => m !== modelId) : [...prevModels, modelId]

            return {
                ...state,
                filters: { ...state.filters, models },
            }
        }

        case TOGGLE_VMAP_ALL_MODEL_FILTERS: {
            const areAllModelsSelected = state.filters.models.length === state.allModels.length
            const models = areAllModelsSelected ? [] : state.allModels.map(vm => vm.id)

            return {
                ...state,
                filters: {
                    ...state.filters,
                    models,
                },
            }
        }

        case RESET_VMAP_MODEL_FILTERS: {
            const models = state.allModels.map(vm => vm.id)

            return {
                ...state,
                filters: { ...state.filters, models },
            }
        }

        case SET_ALL_VMAP_MODELS:
            return {
                ...state,
                allModels: action.payload,
            }

        case TOGGLE_TASK_PRIORITY_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    priorities: {
                        ...state.filters.priorities,
                        isFilterActive: !state.filters.priorities.isFilterActive,
                    },
                },
            }
        }

        case TOGGLE_TASK_PRIORITY: {
            const toggledPriority: ExecutionPriority = action.payload
            const isSelected = state.filters.priorities.toggledPriorities.includes(toggledPriority)
            const toggledPriorities = isSelected
                ? state.filters.priorities.toggledPriorities.filter(priority => priority !== toggledPriority)
                : [...state.filters.priorities.toggledPriorities, toggledPriority]

            // Close the priority filter when deselecting all options
            if (toggledPriorities.length === 0) {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        priorities: INITIAL_STATE.filters.priorities,
                    },
                }
            } else {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        priorities: {
                            ...state.filters.priorities,
                            toggledPriorities,
                        },
                    },
                }
            }
        }

        case SET_ORGANIZATION_VMAP_FILTER: {
            return { ...state, filters: { ...state.filters, organization: action.payload } }
        }

        default:
            return state
    }
}

type OptionsToggleState = Record<string, boolean> | boolean

function toggleAllSubOptions(prevState: OptionsToggleState): OptionsToggleState {
    if (!isObject(prevState)) {
        return prevState
    }

    return isAllSubOptionsActive(prevState) ? mapValues(prevState, () => false) : mapValues(prevState, () => true)
}

const toggleStateChild = (
    data: { parent: VmapVehicleStatusFilterWithChild; child: ChildStatusKey },
    currentState: VmapVehicleStatusFilter,
) => ({
    ...currentState,
    [data.parent]: {
        ...currentState[data.parent],
        // @ts-ignore Fix later
        [data.child]: !currentState[data.parent][data.child],
    },
})

const toggleStateParent = (parent: VmapVehicleStatusFilterKey, currentState: VmapVehicleStatusFilter) => ({
    ...currentState,
    [parent]:
        typeof currentState[parent] === 'boolean' ? !currentState[parent] : toggleAllSubOptions(currentState[parent]),
})

const toggleAllOptions = (currentState: VmapVehicleStatusFilter, value: boolean): VmapVehicleStatusFilter => ({
    ready: value,
    bounty: mapValues(currentState.bounty, () => value),
    ready_need: mapValues(currentState.ready_need, () => value),
    off_market: mapValues(currentState.off_market, () => value),
    booked: value,
    storage: value,
    in_repair: value,
    riding: value,
    lost: value,
    unavailable: value,
    home: value,
})

const toggleAllStateFilters = (currentState: VmapVehicleStatusFilter): VmapVehicleStatusFilter => {
    const allStatesActive = isAllStatesActive(currentState)
    return allStatesActive ? toggleAllOptions(currentState, false) : toggleAllOptions(currentState, true)
}

const addZoneFilter = (filters: VmapFilters, city: VmapZoneFilter): VmapFilters => {
    return {
        ...filters,
        city,
    }
}

const removeZoneFilter = (filters: VmapFilters): VmapFilters => {
    return {
        ...filters,
        city: null,
    }
}
