export enum VehiclePricingType {
    Scooter = 'scooter',
    EBike = 'bike',
}

export enum VehiclePricingTimeslotType {
    Day = 'day',
    Night = 'night',
}

export enum VehiclePricingWeekday {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday',
}

export enum VehiclePricingPricePartType {
    PerMinuteCost = 'perMinuteCost',
    UnlockFee = 'unlockFee',
}

export interface GetVehiclePricingResponse {
    data: {
        [key: string]: GetVehiclePricingResponseItem[]
    }
}

export interface GetVehiclePricingResponseItem {
    id: string
    priceParts: Record<
        VehiclePricingPricePartType,
        {
            amount: number
            component: string
            unit: string
        }
    >
    timeZoneLocation: string
    timeslot: string
    timeslotEnd: string
    timeslotStart: string
    vehicleCategory: string
    weekday: string
    zoneId: string
}

export interface AllVehiclesPricing {
    [VehiclePricingType.Scooter]: VehiclePricingItem[]
    [VehiclePricingType.EBike]: VehiclePricingItem[]
}

export interface VehiclePricingItem {
    id: string
    zoneId: string
    timeZoneLocation: string
    timeslot: VehiclePricingTimeslotType
    timeslotStart: string
    timeslotEnd: string
    vehicleCategory: VehiclePricingType
    weekday: VehiclePricingWeekday
    priceParts: {
        [VehiclePricingPricePartType.PerMinuteCost]: VehiclePricingPricePart
        [VehiclePricingPricePartType.UnlockFee]: VehiclePricingPricePart
    }
}

export interface VehiclePricingPricePart {
    component: string
    amount: number
    unit: string
}

export enum VoiPassPeriodUnitType {
    Day = 'DAY',
    Month = 'MONTH',
    Year = 'YEAR',
}

interface GetVoiPassGroup {
    active: boolean
    currency: string
    description: string
    discountAmount: number
    discountPeriod: number
    billingCyclesCount: number
    discountId: string
    id: string
    minutesIncluded: number
    name: string
    period: number
    periodUnit: VoiPassPeriodUnitType
    price: number
    promotionCategory: string
    promotionGeneration: string
    recurrent: boolean
    validFrom: number
    validTo: number
    zones: string[]
    rideZoneIds: string[]
    uuidMask: string
}

export interface GetVoiPassGroupsResponse {
    data: GetVoiPassGroup[]
}

export interface VoiPassGroup {
    active: boolean
    currency: string
    description: string
    discountAmount: number
    billingCyclesCount: number
    discountId?: string
    id?: string
    minutesIncluded?: number
    name: string
    period: number
    periodUnit: VoiPassPeriodUnitType
    price: number
    promotionCategory: string
    recurrent: boolean
    validFrom: number
    validTo: number
    zones: string[]
    rideZoneIds: string[]
    uuidMask: string
}

export interface VoiPassDiscount {
    active: boolean
    discountAmount: number
    startAt: number
    endAt: number
    zoneId: string
    productName: string | undefined
    billingCyclesCount: number
}

export interface UpdateVoiPassDiscountRequest {
    discountId: string
    discountAmount: number
    startAt: number
    endAt: number
    zoneId: string
    billingCyclesCount: number
}
