import { type BulkListVehicle, type PrettifiedTask } from 'src/utils/scooterBulk/types'
import { prettifyVehicleAvailability } from 'src/utils/vehicles/vehiclesUtils'
import { firstLetterUpperCase } from 'src/utils/string/stringUtils'
import {
    type LicensePlate,
    LicensePlateDeactivationStatus,
    type LicensePlatesDeactivationResult,
} from 'src/models/licensePlate'
import { type Availability, type Vehicle, type VehicleStatus } from 'src/api/fm/vehicles/vehicles.model'
import { type ZoneOption } from 'src/redux/zone/zone.types'
import { prettifyTaskType } from 'src/utils/tasks/tasksUtils'
import { EXECUTION_PRIORITY_LABELS } from 'src/api/fm/tasks/tasks.model'

export function vehicleToBulkListVehicle(
    vehicle: Vehicle,
    zoneOptions: ZoneOption[],
    licensePlates?: LicensePlate[],
    licensePlatesDeactivationResult?: LicensePlatesDeactivationResult,
): BulkListVehicle {
    const zone = zoneOptions.find(z => z.value === vehicle.zone_id)
    const licensePlateNumber = getLicensePlateNumber(vehicle.id, licensePlates)
    const licensePlateDeactivationStatus = getLicensePlateDeactivationStatus(
        vehicle.id,
        licensePlatesDeactivationResult,
    )
    const prettifiedTasks = getPrettifiedTasks(vehicle.availability)

    return {
        id: vehicle.id,
        short: vehicle.short,
        battery: `${vehicle.battery}%`,
        location: vehicle.location,
        vehicle_state: prettifyVehicleAvailability(vehicle.availability),
        availability: vehicle.availability,
        prettifiedTasks,
        owner: vehicle.owner,
        zone: zone ? zone.label : undefined,
        category: vehicle.modelSpecification ? firstLetterUpperCase(vehicle.modelSpecification.category) : undefined,
        licensePlate: {
            number: licensePlateNumber,
            deactivationStatus: licensePlateDeactivationStatus,
        },
        warehouse: vehicle.warehouse?.name,
    }
}

const getPrettifiedTasks = (availability: Availability): PrettifiedTask[] => {
    const { tasks } = availability

    if (tasks && tasks.length > 0) {
        return tasks.map(task => ({
            type: task.type ? prettifyTaskType(task.type) : undefined,
            status: task.status ? firstLetterUpperCase(task.status) : undefined,
            priority: task.executionPriority ? EXECUTION_PRIORITY_LABELS[task.executionPriority] : undefined,
            riderIncentivized: task.riderIncentivized,
        }))
    }

    return []
}

const STATUS_MAPPING: Record<VehicleStatus, string | undefined> = {
    ready: undefined,
    ready_need: 'New',
    bounty: 'New',
    storage: 'New',
    off_market: 'Pending',
    in_repair: undefined,
    home: undefined,
    lost: undefined,
    riding: undefined,
    booked: undefined,
    unavailable: undefined,
    decommissioned: undefined,
    sold: undefined,
}

export function getTaskTypeStatus(availability: { status: VehicleStatus }): string | undefined {
    return STATUS_MAPPING[availability.status]
}

function getLicensePlateNumber(vehicleId: string, licensePlates: LicensePlate[] | undefined): string | undefined {
    return licensePlates?.find(license => license.vehicleId === vehicleId)?.number
}

function getLicensePlateDeactivationStatus(
    vehicleId: string,
    licensePlatesDeactivationResult: LicensePlatesDeactivationResult | undefined,
): LicensePlateDeactivationStatus | undefined {
    if (!licensePlatesDeactivationResult) {
        return undefined
    }

    const failed = licensePlatesDeactivationResult.failedVehicles.includes(vehicleId)

    if (failed) {
        return LicensePlateDeactivationStatus.Failed
    }
    const succesful = licensePlatesDeactivationResult.successfulVehicles.includes(vehicleId)

    if (succesful) {
        return LicensePlateDeactivationStatus.Succeeded
    }

    return undefined
}

export type VehicleIdentifierType = Extract<keyof Vehicle, 'id' | 'short' | 'vin'>

export const getVehiclesNotFound = (
    searchIdentifiers: string[],
    foundVehicles: Vehicle[],
    identifierType: VehicleIdentifierType,
): string[] => {
    const foundVehiclesIdentifiers = foundVehicles.map(vehicle => vehicle[identifierType])
    const isIdentifierCaseSensitive = identifierType === 'vin'
    const notFoundVehiclesIdentifiers = searchIdentifiers.filter(
        searchIdentifier =>
            !foundVehiclesIdentifiers.includes(
                isIdentifierCaseSensitive ? searchIdentifier : searchIdentifier.toLowerCase(),
            ),
    )

    return notFoundVehiclesIdentifiers
}
