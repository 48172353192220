import { put, takeEvery, call, select, type SagaReturnType } from 'redux-saga/effects'
import { createPromotion, getPromotions, updatePromotion } from 'src/api'
import { filterCampaigns, searchCampaigns } from 'src/components/app/utils/campaigns/campaignsUtils'
import { selectCampaignFilters, selectOgCampaigns, selectSearchTerm } from 'src/redux/campaigns/campaigns.selectors'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import {
    GET_CAMPAIGNS,
    APPLY_CAMPAIGN_FILTERS,
    SEARCH_CAMPAIGNS,
    CREATE_CAMPAIGN,
    EDIT_CAMPAIGN,
    type ApplyCampaignFilters,
    type SearchCampaigns,
    type CreateCampaign,
    type EditCampaign,
    type CampaignsFilters,
} from 'src/redux/campaigns/campaigns.types'
import {
    setCampaignFilters,
    setCampaigns,
    setOgCampaigns,
    getCampaigns as getCampaignsAction,
} from 'src/redux/campaigns/campaigns.actions'
import { type Campaign } from 'src/api/fm/promotions/promotions.model'

export function* getCampaigns() {
    try {
        const campaigns: Campaign[] = yield call(getPromotions, { pagination: { Number: 0, Size: 10000 } })
        const filters: CampaignsFilters = yield select(selectCampaignFilters)
        if (campaigns) {
            yield put(setOgCampaigns(campaigns))
            yield put(setCampaigns(filterCampaigns(campaigns, filters)))
        }
    } catch (e) {
        notifyUser({ message: 'Something went wrong when getting campaigns' }, 'error')
    }
}

export function* applyFilters(action: ApplyCampaignFilters) {
    try {
        const filters = action.payload
        yield put(setCampaignFilters(filters))
        const campaigns: Campaign[] = yield select(selectOgCampaigns)
        const searchTerm: string = yield select(selectSearchTerm)
        const found = searchCampaigns(campaigns, searchTerm)
        yield put(setCampaigns(filterCampaigns(found, filters)))
    } catch (e) {
        notifyUser({ message: 'Something went wrong when applying filters' }, 'error')
    }
}

export function* search(action: SearchCampaigns) {
    try {
        const searchTerm = action.payload
        const campaigns: Campaign[] = yield select(selectOgCampaigns)
        const filters: CampaignsFilters = yield select(selectCampaignFilters)
        const found = searchCampaigns(campaigns, searchTerm)
        yield put(setCampaigns(filterCampaigns(found, filters)))
    } catch (e) {
        notifyUser({ message: 'Something went wrong when searching campaigns' }, 'error')
    }
}

type CreatePromotionRes = SagaReturnType<typeof createPromotion>

export function* create(action: CreateCampaign) {
    const campaign = action.payload
    const result: CreatePromotionRes = yield call(createPromotion, campaign)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Created campaign', 'success')
    yield put(getCampaignsAction())
}

type UpdatePromotionRes = SagaReturnType<typeof updatePromotion>

export function* edit(action: EditCampaign) {
    const { campaignId, campaign } = action.payload
    const result: UpdatePromotionRes = yield call(updatePromotion, campaignId, campaign)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Updated campaign', 'success')
    yield put(getCampaignsAction())
}

export default function* watcher() {
    yield takeEvery(GET_CAMPAIGNS, getCampaigns)
    yield takeEvery(APPLY_CAMPAIGN_FILTERS, applyFilters)
    yield takeEvery(SEARCH_CAMPAIGNS, search)
    yield takeEvery(CREATE_CAMPAIGN, create)
    yield takeEvery(EDIT_CAMPAIGN, edit)
}
