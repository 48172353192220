import { type AreaProperties } from 'src/api/fm/zones/zones.model'

function NoParking({ name, rules }: Pick<AreaProperties, 'name' | 'rules'>) {
    return (
        <>
            <strong>{name}</strong>
            <p>No parking zone</p>
            {rules.vehicle_types && (
                <p>
                    Vehicle Types: <strong>{rules.vehicle_types.join(',')}</strong>
                </p>
            )}
        </>
    )
}

function NoRiding({ name, rules }: Pick<AreaProperties, 'name' | 'rules'>) {
    return (
        <>
            <strong>{name}</strong>
            <p>No riding zone</p>
            <p>
                Disable throttle: <strong>{rules.noRiding_isEnforced ? 'On' : 'Off'}</strong>
            </p>
            {rules.vehicle_types && (
                <p>
                    Vehicle Types: <strong>{rules.vehicle_types.join(',')}</strong>
                </p>
            )}
        </>
    )
}

function OperationsArea({ name, rules }: Pick<AreaProperties, 'name' | 'rules'>) {
    return (
        <>
            <strong>{name}</strong>
            <p>Operations area</p>
            {rules.vehicle_types && (
                <p>
                    Vehicle Types: <strong>{rules.vehicle_types.join(',')}</strong>
                </p>
            )}
        </>
    )
}

function BountyArea({ name, rules }: Pick<AreaProperties, 'name' | 'rules'>) {
    return (
        <>
            <strong>{name}</strong>
            <p>Bounty area</p>
            {rules.vehicle_types && (
                <p>
                    Vehicle Types: <strong>{rules.vehicle_types.join(',')}</strong>
                </p>
            )}
        </>
    )
}

function SoftMpz({ name, rules }: Pick<AreaProperties, 'name' | 'rules'>) {
    return (
        <>
            <strong>{name}</strong>
            <p>Soft MPZ Area</p>
            {rules.vehicle_types && (
                <p>
                    Vehicle Types: <strong>{rules.vehicle_types.join(',')}</strong>
                </p>
            )}
        </>
    )
}

function FreeFloating({ name, rules }: Pick<AreaProperties, 'name' | 'rules'>) {
    return (
        <>
            <strong>{name}</strong>
            <p>Free Floating Area</p>
            {rules.vehicle_types && (
                <p>
                    Vehicle Types: <strong>{rules.vehicle_types.join(',')}</strong>
                </p>
            )}
        </>
    )
}

function SlowZone({ name, rules }: Pick<AreaProperties, 'name' | 'rules'>) {
    return (
        <>
            <strong>{name}</strong>
            <p>Slow zone</p>
            {rules.slowZone_maxSpeed && (
                <p>
                    Max speed: <strong>{rules.slowZone_maxSpeed}</strong>
                </p>
            )}
            {rules.vehicle_types && (
                <p>
                    Vehicle Types: <strong>{rules.vehicle_types.join(',')}</strong>
                </p>
            )}
        </>
    )
}

export function AreaTooltipContent({ areaProperties }: { areaProperties: AreaProperties }) {
    const { area_type, name, rules } = areaProperties

    switch (area_type) {
        case 'no-parking':
            return <NoParking name={name} rules={rules} />
        case 'no-riding':
            return <NoRiding name={name} rules={rules} />
        case 'operations':
            return <OperationsArea name={name} rules={rules} />
        case 'bounty':
            return <BountyArea name={name} rules={rules} />
        case 'slow-zone':
            return <SlowZone name={name} rules={rules} />
        case 'soft-mpz':
            return <SoftMpz name={name} rules={rules} />
        case 'free-floating':
            return <FreeFloating name={name} rules={rules} />
        default:
            return null
    }
}
