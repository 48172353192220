import { CompositeLayer, IconLayer, WebMercatorViewport } from 'deck.gl'
import RBush from 'rbush'
import { type IconLayerProps } from '@deck.gl/layers'
import type { UpdateStateInfo } from '@deck.gl/core/lib/layer'
import type { ViewStateProps } from '@deck.gl/core/lib/deck'
import { isVehicleSelected } from 'src/components/vmap/bulk-list/types'
import { getCluster, getIconSize, getIconName } from 'src/components/vmap/icon-cluster-layer/iconClusterLayerUtils'
import { DEBUG } from 'src/utils/env'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'

export interface WithLocationAndId extends Record<string, any> {
    id: string
    location: [number, number]
}

export interface WithXandY extends Record<string, any> {
    x: number
    y: number
}

class VehicleRBush extends RBush<WithXandY> {
    toBBox({ x, y }: WithXandY) {
        return { minX: x, minY: y, maxX: x, maxY: y }
    }
    compareMinX(a: WithXandY, b: WithXandY) {
        return a.x - b.x
    }
    compareMinY(a: WithXandY, b: WithXandY) {
        return a.y - b.y
    }
}

// FIXME: Remove these dependencies by extracting the getIcon function and passing it down
interface Props<T extends WithLocationAndId> extends IconLayerProps<T> {
    bulkList: BulkListVehicle[] // Used for deciding if vehicle is selected (by finding id)
    isSleeping: boolean // Used for deciding if we should show sleep icon for vehicle
    hoveredItems: T[] // Used for deciding if we should show hovered icon for vehicle
    sizeScale: number
}

export default class IconClusterLayer<T extends WithLocationAndId> extends CompositeLayer<any, Props<T>> {
    initializeState() {
        this.setState({
            tree: new VehicleRBush(),
            version: -1,
            z: -1,
            width: 0,
            height: 0,
        })
    }

    shouldUpdateState({ changeFlags }: UpdateStateInfo<Props<T>>) {
        return changeFlags.somethingChanged
    }

    updateState({ props, oldProps, changeFlags }: UpdateStateInfo<Props<T>>) {
        const { viewport } = this.context

        const { width, height } = viewport

        if (changeFlags.dataChanged || props.bulkList !== oldProps.bulkList) {
            let version

            if (DEBUG) {
                const a0 = performance.now()
                version = this._updateCluster(props, viewport)
                const a1 = performance.now()
                const diff = a1 - a0
                const ms = Math.floor(diff)
                const fps = Math.floor(1000 / diff)

                if (fps < 60) {
                    console.warn(`Call to _updateCluster took ${ms} ms (${fps} fps)`)
                } else {
                    console.log(`Call to _updateCluster took ${ms} ms (${fps} fps)`)
                }
            } else {
                version = this._updateCluster(props, viewport)
            }

            this.setState({ version, width, height })
        }
        this.setState({
            z: Math.round(viewport.zoom) + 2,
        })
    }

    renderLayers() {
        const { z, version } = this.state
        const {
            data,
            bulkList,
            iconAtlas,
            iconMapping,
            sizeScale,
            getPosition,
            onHover,
            onClick,
            isSleeping,
            hoveredItems,
        } = this.props

        return new IconLayer(
            this.getSubLayerProps({
                id: 'icon',
                data,
                iconAtlas,
                iconMapping,
                sizeScale,
                getPosition,
                getIcon: (d: any) => {
                    const isSelected = isVehicleSelected(d, bulkList)
                    const isHovered =
                        Array.isArray(hoveredItems) && hoveredItems.length === 1
                            ? hoveredItems.some(i => i.id === d.id)
                            : false
                    return d.zoomLevels[z] && getIconName(d.zoomLevels[z].cluster, isSelected, isSleeping, isHovered)
                },
                getSize: (d: any) => d.zoomLevels[z] && d.zoomLevels[z].size,
                onHover,
                onClick,
                updateTriggers: {
                    getIcon: { version, z, hoveredItems },
                    getSize: { version, z },
                },
                opacity: 0.8,
            }),
        )
    }

    // Compute icon clusters
    // We use the projected positions instead of longitude and latitude to build
    // the spatial index, because this particular dataset is distributed all over
    // the world, we can't use some fixed deltaLon and deltaLat
    _updateCluster({ data, sizeScale }: { data?: any; sizeScale: number }, viewport: ViewStateProps) {
        const { tree, version } = this.state

        if (!data) {
            return version
        }

        const transform = new WebMercatorViewport({
            ...viewport,
            zoom: 0,
        })

        // FIXME: Get rid of TS any declarations in this file.
        // Today we add x, y and zoomLevels to each element in the Vehicle[] type
        // passed in as data for this layer.
        // If we could perform the same behaviour but by allocating a new array
        // or similar we could use stricter types
        data.forEach((p: any) => {
            const screenCoords = transform.project([p.location[1], p.location[0]])
            p.x = screenCoords[0]
            p.y = screenCoords[1]
            p.zoomLevels = []
        })

        tree.clear()
        tree.load(data)

        for (let z = 2; z <= 22; z++) {
            const radius = sizeScale / Math.sqrt(2) / Math.pow(2, z)
            data.forEach((p: any) => {
                if (p.zoomLevels[z] === undefined) {
                    // this point does not belong to a cluster
                    const { x, y } = p
                    // find all points within radius that do not belong to a cluster
                    const neighbors = tree
                        .search({
                            minX: x - radius,
                            minY: y - radius,
                            maxX: x + radius,
                            maxY: y + radius,
                        })
                        .filter((neighbor: any) => neighbor.zoomLevels[z] === undefined)

                    // only show the center point at this zoom level
                    // points = objects of all neighbours that are part of cluster
                    neighbors.forEach((neighbor: any) => {
                        if (neighbor === p) {
                            p.zoomLevels[z] = {
                                cluster: getCluster(p, neighbors.length),
                                size: getIconSize(neighbors.length),
                                points: neighbors,
                            }
                        } else {
                            neighbor.zoomLevels[z] = null
                        }
                    })
                }
            })
        }

        return version + 1
    }
}
