import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import {
    type AssessmentItems,
    type GetRideHistoryResponse,
    type GetRideResponse,
    type Ride,
    type RideHistoryItem,
    type RideHistoryResponseItem,
    type ParkingPhotoDecision,
} from 'src/api/fm/rentals/rentals.model'

type EndRideData = {
    newDuration: number
    source: string
    rental_id: string
}

export const MAAS_SOURCE = 'maas'
export const RIDER_SOURCE = 'rides'

export async function endRide(data: EndRideData): Promise<undefined | Error> {
    const url = `v1/fm/rentals/${data.rental_id}/end`
    const body = {
        source: data.source,
        newDuration: data.newDuration,
    }
    const headers = await getHeaders()

    return ins.post(url, body, { headers })
}

export const getAllActiveRides = async (): Promise<Ride[] | Error> => {
    const link = '/v1/fm/rentals/active'
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

type RideHistoryParams = {
    date: string | null
    page_number: number
    page_size: number
    source?: typeof RIDER_SOURCE | typeof MAAS_SOURCE
    sort_field?: string
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/rentals/getVehicleHistory API documentation}
 */
export const getRideHistoryVehicle = async (id: string, params: RideHistoryParams) => {
    const url = encodeURI(`v2/fm/rentals/vehicle/${id}`)
    const headers = await getHeaders()
    return ins.get<GetRideHistoryResponse>(url, { params, headers }).then(res => mapGetRideHistory(res.data))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/rentals/getUserRentalHistory API documentation}
 */
export const getRideHistoryUser = async (id: string, params: RideHistoryParams): Promise<RideHistoryItem[] | Error> => {
    const body = {
        ...params,
    }
    const url = encodeURI(`v1/fm/rentals/users/${id}`)
    const headers = await getHeaders()
    return ins.post<GetRideHistoryResponse>(url, body, { headers }).then(res => mapGetRideHistory(res.data))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/rentals/getRentalHistoryByID API documentation}
 */
export const getRideHistoryById = async (rideId: string): Promise<RideHistoryItem | Error> => {
    const url = encodeURI(`v1/fm/rentals/${rideId}`)
    const headers = await getHeaders()
    return ins
        .get<GetRideResponse>(url, { headers })
        .then(res => mapGetRideHistoryById(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getActiveRideUser = async (id: string) => {
    const url = encodeURI(`v1/fm/rentals/active/user/${id}`)
    const headers = await getHeaders()
    return ins
        .get(url, { headers })
        .then(res => res) // We don't need the error case on this endpoint
        .catch(() => null)
}

export const getActiveRideVehicle = async (id: string) => {
    const url = encodeURI(`v1/fm/rentals/active/vehicle/${id}`)
    const headers = await getHeaders()
    return ins
        .get(url, { headers })
        .then(res => res) // We don't need the error case on this endpoint
        .catch(() => null)
}

export const refund = async (id: string, body: { source: string; amount: number; user_id: string }) => {
    const link = encodeURI(`v1/fm/rentals/${id}/refund`)
    const headers = await getHeaders()

    return ins
        .post(link, body, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapRideHistoryItem = (historyItem: RideHistoryResponseItem): RideHistoryItem => {
    let parkingReviewField: Partial<RideHistoryItem> | undefined

    if (historyItem.parking_review) {
        parkingReviewField = {
            parkingReview: {
                assessment: historyItem.parking_review.assessment as AssessmentItems,
                decision: historyItem.parking_review.decision as ParkingPhotoDecision,
                reviewCompletedAt: historyItem.parking_review.review_completed_at,
                reviewedBy: historyItem.parking_review.reviewed_by,
                abandonedRide: historyItem.parking_review.abandoned_ride,
            },
        }
    }

    return {
        autoEndedLabel: historyItem.auto_ended,
        cost: historyItem.cost,
        credit: historyItem.credit,
        currency: historyItem.currency,
        discount: historyItem.discount,
        endAddress: historyItem.end_address,
        endTime: historyItem.end_time,
        isManuallyEnded: historyItem.manual_ended,
        hasParkingPhoto: historyItem.parking_photo,
        ...parkingReviewField,
        paymentMethodBrand: historyItem.payment_method_brand,
        paymentMethodInfo: historyItem.payment_method_info,
        perMinuteCost: historyItem.per_minute_cost,
        receiptLink: historyItem.receipt_link,
        rentalId: historyItem.rental_id,
        startAddress: historyItem.start_address,
        startTime: historyItem.start_time,
        userId: historyItem.user_id,
        vehicleCode: historyItem.vehicle_code,
        zoneId: historyItem.zone_id,
        startLocation: historyItem.start_location,
        endLocation: historyItem.end_location,
    }
}

const mapGetRideHistoryById = (response: GetRideResponse): RideHistoryItem => mapRideHistoryItem(response.data)
const mapGetRideHistory = (response: GetRideHistoryResponse): RideHistoryItem[] =>
    response?.data?.map(mapRideHistoryItem) ?? []
