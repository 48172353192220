export const GET_NEXT_PHOTO_TO_LABEL = 'GET_NEXT_PHOTO_TO_LABEL'
export const LABEL_PHOTO = 'LABEL_PHOTO'
export const SET_NEXT_PHOTO_TO_LABEL = 'SET_NEXT_PHOTO_TO_LABEL'

export interface PhotoLabelsState {
    photo: PhotoToLabel | null
}

export type PhotoLabelReducerActions = SetNextPhotoToLabelAction

export interface PhotoToLabel {
    kind: string
    photoRef: string
    photoUrl: string
    rideId: string
    userId: string
    zoneId: string
    createdAt: number
    takenAt: number
}

export interface SetNextPhotoToLabelAction {
    type: typeof SET_NEXT_PHOTO_TO_LABEL
    payload: PhotoToLabel
}

export interface LabelPhotoAction {
    type: typeof LABEL_PHOTO
    payload: { photoRef: string; labels: string[] }
}

export interface GetNextPhotoToLabelAction {
    type: typeof GET_NEXT_PHOTO_TO_LABEL
    payload: null
}
