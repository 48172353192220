import { type CloseTaskStatus, type Task } from 'src/api/fm/tasks/tasks.model'
import { Popup } from 'src/ui-kit/popup/Popup'
import { getDropTaskTitle } from 'src/components/parts/active-tasks/Utils'
import { Button } from 'src/ui-kit/button/Button'
import { WarehouseSelectionModal } from 'src/components/warehouseSelectionModal/WarehouseSelectionModal'

export interface UpdateTaskPayload {
    taskId: string
    vehicleId: string
    status: CloseTaskStatus
    accountId: string
    warehouseId: string | undefined
}

export interface UpdateTaskData {
    task: Task
    status: CloseTaskStatus
}

interface Props {
    close: () => void
    data: UpdateTaskData
    updateTask: (data: UpdateTaskPayload) => void
}

export const DropScooter = (props: Props) => {
    const { close, data, updateTask } = props

    const handleSubmit = (warehouseId?: string) => {
        updateTask({
            taskId: data.task.id,
            vehicleId: data.task.vehicleId,
            status: data.status,
            accountId: data.task.accountId!,
            warehouseId,
        })
        close()
    }

    return data.task.type === 'transport' ? (
        <WarehouseSelectionModal select={warehouse => handleSubmit(warehouse.id)} cancel={close} />
    ) : (
        <Popup title={getDropTaskTitle(data.status)} close={close}>
            <Button dataTestId='confirm-btn-drop-vehicle-task' isStretched onClick={() => handleSubmit()}>
                Confirm
            </Button>
        </Popup>
    )
}
