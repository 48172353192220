import { type NavigateFunction } from 'react-router-dom'
import {
    type Organization,
    type SetCurrentOrganization,
    type DeleteOrganizationSucceeded,
    type EditOrganizationSucceeded,
    type CreateOrganizationSucceeded,
} from 'src/redux/organization/organization.types'
import { type Task } from 'src/api/fm/tasks/tasks.model'

export const SEARCH_HUNTER = 'SEARCH_HUNTER'
export const GET_HUNTER = 'GET_HUNTER'
export const SET_CURRENT_HUNTER = 'SET_CURRENT_HUNTER'
export const FETCH_HUNTER_FAILED = 'FETCH_HUNTER_FAILED'
export const RESET_CURRENT_HUNTER = 'RESET_CURRENT_HUNTER'
export const SUBMIT_EDITED_HUNTER = 'SUBMIT_EDITED_HUNTER'
export const SUBMIT_NEW_HUNTER = 'SUBMIT_NEW_HUNTER'
export const DELETE_HUNTER = 'DELETE_HUNTER'
export const GET_HUNTER_TASK_HISTORY_NEXT_PAGE = 'GET_HUNTER_TASK_HISTORY_NEXT_PAGE'
export const INCREMENT_HUNTER_TASK_HISTORY_PAGINATION = 'INCREMENT_HUNTER_TASK_HISTORY_PAGINATION'
export const SET_HUNTER_TASK_HISTORY_END_OF_PAGINATION = 'SET_HUNTER_TASK_HISTORY_END_OF_PAGINATION'
export const RESET_HUNTER_HISTORY_FOR_HUNTER = 'RESET_HUNTER_HISTORY_FOR_HUNTER'
export const GET_HUNTER_HISTORY = 'GET_HUNTER_HISTORY_FOR_HUNTER'
export const SET_HUNTER_HISTORY = 'SET_HUNTER_HISTORY_FOR_HUNTER'
export const SET_IS_FETCHING_HUNTER_HISTORY = 'SET_IS_FETCHING_HUNTER_HISTORY'
export const SET_HUNTER_HISTORY_SORT_PARAM_FOR_HUNTER = 'SET_HUNTER_HISTORY_SORT_PARAM_FOR_HUNTER'
export const SET_ALL_ORGS = 'SET_ALL_ORGS'
export const GET_ALL_ORGANIZATIONS = 'GET_ALL_ORGANIZATIONS'
export const SORT_HUNTER_HISTORY_FOR_HUNTER = 'SORT_HUNTER_HISTORY_FOR_HUNTER'
export const RESET_HUNTER_PASSWORD = 'RESET_HUNTER_PASSWORD'

export interface HunterState {
    hunter: Hunter | undefined
    hunterOrganization: Organization | null
    hunterHistory: Task[]
    isFetchingHunterHistory: boolean
    hunterHistorySortParam: string
    hunterHistoryPageNum: number
    hunterHistoryEndOfPagination: boolean
    organizations: Organization[]
}

export interface Hunter {
    accountId: string
    organizationId: string | undefined
    name: string
    email: string
    phone: string | undefined
}

export interface GetHunter {
    type: typeof GET_HUNTER
    payload: string
}

export interface SetCurrentHunter {
    type: typeof SET_CURRENT_HUNTER
    payload: Hunter
}

export interface ResetCurrentHunter {
    type: typeof RESET_CURRENT_HUNTER
}

export interface SubmitEditedHunter {
    type: typeof SUBMIT_EDITED_HUNTER
    payload: Hunter
}

export interface SubmitNewHunter {
    type: typeof SUBMIT_NEW_HUNTER
    payload: { hunter: Omit<Hunter, 'accountId'>; navigateFn: NavigateFunction }
}

export interface DeleteHunter {
    type: typeof DELETE_HUNTER
    payload: { id: string; navigateFn: NavigateFunction; reason: string }
}

export interface GetHunterHistory {
    type: typeof GET_HUNTER_HISTORY
}

export interface SetHunterHistory {
    type: typeof SET_HUNTER_HISTORY
    payload: Task[]
}

export interface SetIsFetchingHunterHistory {
    type: typeof SET_IS_FETCHING_HUNTER_HISTORY
    payload: boolean
}

export interface ResetHunterHistoryForHunter {
    type: typeof RESET_HUNTER_HISTORY_FOR_HUNTER
}

export interface ResetHunterPassword {
    type: typeof RESET_HUNTER_PASSWORD
    payload: string
}

export interface SetHunterHistorySortParamForHunter {
    type: typeof SET_HUNTER_HISTORY_SORT_PARAM_FOR_HUNTER
    payload: string
}

export interface GetOrganizations {
    type: typeof GET_ALL_ORGANIZATIONS
}

export interface SetAllOrgs {
    type: typeof SET_ALL_ORGS
    payload: Organization[]
}

export interface SearchHunter {
    type: typeof SEARCH_HUNTER
    payload: { searchTerm: string; navigateFn: NavigateFunction }
}

export interface SortHunterHistory {
    type: typeof SORT_HUNTER_HISTORY_FOR_HUNTER
    payload: string
}

export interface GetHunterTaskHistoryNextPage {
    type: typeof GET_HUNTER_TASK_HISTORY_NEXT_PAGE
}

export interface IncrementHunterTaskHistoryPagination {
    type: typeof INCREMENT_HUNTER_TASK_HISTORY_PAGINATION
}

export interface SetHunterTaskHistoryEndOfPagination {
    type: typeof SET_HUNTER_TASK_HISTORY_END_OF_PAGINATION
}

export type HunterActions =
    | GetHunter
    | SetCurrentHunter
    | ResetCurrentHunter
    | SubmitEditedHunter
    | SubmitNewHunter
    | DeleteHunter
    | GetHunterHistory
    | SetHunterHistory
    | SetIsFetchingHunterHistory
    | ResetHunterHistoryForHunter
    | ResetHunterPassword
    | SetHunterHistorySortParamForHunter
    | GetOrganizations
    | SetAllOrgs
    | SearchHunter
    | SortHunterHistory
    | GetHunterTaskHistoryNextPage
    | IncrementHunterTaskHistoryPagination
    | SetHunterTaskHistoryEndOfPagination
    | SetCurrentOrganization
    | CreateOrganizationSucceeded
    | EditOrganizationSucceeded
    | DeleteOrganizationSucceeded
