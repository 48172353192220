import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import { mapRiderVoucherDetails } from 'src/api/fm/discounts/utils'
import { type GetVoucherDetailsResponse, type RiderVoucherDetails } from 'src/api/fm/discounts/discounts.model'

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/discounts/getDiscounts API documentation}
 * @param riderId
 */

export const getRiderVoucherHistory = async (riderId: string): Promise<RiderVoucherDetails | Error> => {
    const link = encodeURI(`/v1/fm/discounts/users/${riderId}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then((res: GetVoucherDetailsResponse) => mapRiderVoucherDetails(res))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/discounts/deleteDiscounts API documentation}
 * @param discountIDs discount ids
 */

export const deleteRiderVouchers = async (discountIDs: Array<string>): Promise<void | Error> => {
    const link = encodeURI('/v1/fm/discounts')
    const headers = await getHeaders()
    return ins
        .delete(link, {
            headers,
            data: {
                discountIDs,
            },
        })
        .then(() => {})
        .catch(e => getError(e, 'response.data.errors[0]'))
}
