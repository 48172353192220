import {
    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_ERROR,
    RESET_USER,
    TOGGLE_USER_ROLE,
    UPDATE_USER_ROLES,
    UPDATE_USER_ROLES_SUCCESS,
    UPDATE_USER_ROLES_ERROR,
    type Roles,
    type User,
    type SearchUser,
    type SearchUserSuccess,
    type SearchUserError,
    type ResetUser,
    type ToggleUserRole,
    type UpdateUserRolesSuccess,
    type UpdateUserRoles,
} from 'src/redux/permissions/permissions.types'

export const searchUser = (email: string): SearchUser => ({
    type: SEARCH_USER,
    payload: { email },
})

export const searchUserSuccess = (user: User): SearchUserSuccess => ({
    type: SEARCH_USER_SUCCESS,
    payload: { user },
})

export const searchUserError = (): SearchUserError => ({
    type: SEARCH_USER_ERROR,
})

export const resetUser = (): ResetUser => ({
    type: RESET_USER,
})

export const toggleUserRole = (role: string): ToggleUserRole => ({
    type: TOGGLE_USER_ROLE,
    payload: { role },
})

export const updateUserRoles = (userId: string, roles: Roles): UpdateUserRoles => ({
    type: UPDATE_USER_ROLES,
    payload: { userId, roles },
})

export const updateUserRolesSuccess = (): UpdateUserRolesSuccess => ({
    type: UPDATE_USER_ROLES_SUCCESS,
})

export const updateUserRolesError = () => ({
    type: UPDATE_USER_ROLES_ERROR,
})
