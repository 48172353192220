import {
    GET_RECEIPTS_BULK_URL,
    type GetReceiptsBulkUrl,
    SET_RECEIPTS_BULK_URL,
} from 'src/redux/receipts/receipts.types'
import { type ReceiptsBulkURLResponse, type ReceiptsGetBulkURLParams } from 'src/api/fm/receipts/receipts.model'

export const getReceiptsBulkUrl = (payload: ReceiptsGetBulkURLParams): GetReceiptsBulkUrl => ({
    type: GET_RECEIPTS_BULK_URL,
    payload,
})

export const setReceiptsBulkUrl = (data: ReceiptsBulkURLResponse | null) => ({
    type: SET_RECEIPTS_BULK_URL,
    payload: data,
})
