export const AUTH_TOKEN_URL = 'v1/auth/from_firebase/'

/**
 * Number of seconds before token expiration date
 * when token is already considered as expired.
 * This is a precaution against token becoming expired
 * while the outgoing request reaches BE.
 *
 * Example:
 * - Token expires at 09:23:45
 * - TOKEN_RESERVED_EXP_TIME is set to 10
 * At 09:23:36 token is checked if it has expired, true is returned
 */
export const TOKEN_RESERVED_EXP_TIME = 10
